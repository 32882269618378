import './auth.scss'

import { useContext, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

import supabase from "../../config/supabaseClient"
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Grid } from "@mui/material";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";

const Signup = () => {

    const [error, setError] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const navigate = useNavigate();

    const {dispatch} = useContext(AuthContext);

    const handleSignup = async (e) => {

        e.preventDefault();

        try {

            const { data, error } = await supabase.auth.signUp({
                email: email,
                password: password,
            })

            if (error) {
                console.error(error)
                setError(error)
            }

            // console.log(data)

            const userInfo = data.user

            if (data.user.aud === "authenticated") {

                dispatch({type:"LOGIN", payload:userInfo})

                navigate("/welcome");

            }

        }
        catch (error) {
            console.log(error)
            setError(error)
        }

    }

    return (

        <div className="auth">
            <div className="overlay"></div>
            <video
                src="https://apk-spaces-bucket.sfo3.cdn.digitaloceanspaces.com/MLBTS-Stats-Project%2FSignUpPage-HR-Montage.mp4"
                autoPlay
                muted
                loop
                playsInline
            />
            <div className="content">
                <div className="title">
                    <span>
                        <PersonAddIcon fontSize="large" />
                    </span>
                    <h1>Sign Up</h1>
                </div>
                <form onSubmit={handleSignup}>
                    <input type="email" placeholder="email" onChange={e=>setEmail(e.target.value)} />
                    <input type="password" placeholder="password" onChange={e=>setPassword(e.target.value)}/>
                    <button type="submit">Sign Up</button>
                    {error &&
                        <Grid
                            container
                            className="errorMessage"
                        >
                            <Grid item>
                                Wrong Email or Password
                            </Grid>
                            <Grid item>
                                <SentimentVeryDissatisfiedIcon fontSize="large" className="icon" />
                            </Grid>
                        </Grid>
                    }
                </form>
                <div className="bottom">
                    If you already have an account ->
                    <Link className="link" to="/login">
                        Log In
                    </Link>
                </div>
                {error && (<p>{error}</p>)}
            </div>
        </div>

    )

}

export default Signup