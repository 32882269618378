import '../new.scss';

import { useContext, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "../../../components/sidebar/sidebar";
import { AuthContext } from "../../../context/AuthContext";
import supabase from "../../../config/supabaseClient";
import Footer from "../../../components/footer/footer";
import Swal from "sweetalert2";


const NewPitcher = ({inputs, title}) => {
    const initializeData = () => {
        const initialData = {};
        inputs.forEach(input => {
            if (input.type === "select") {
                initialData[input.id] = input.defaultValue;
            } else {
                initialData[input.id] = '';
            }
        });
        return initialData;
    };

    const [data, setData] = useState(initializeData());
    const [player, setPlayer] = useState({})
    const [fetchError, setFetchError] = useState("")
    const navigate = useNavigate();
    const {year} = useParams();
    const {currentUser} = useContext(AuthContext);
    const current_user_email = currentUser.email
    // console.log(currentUser)

    // console.log(currentUser.id)

    const handleInput = (e) => {

        const id = e.target.id;
        const value = e.target.value

        setData({...data, [id]: value});

        const playerFullName = (data.pitcherName + data.cardType + year).replace(/\s/g, '')
        setPlayer(playerFullName)

        // console.log(data)
        // console.log(playerFullName)

    }

    const handleAdd = async (e) => {

        e.preventDefault();

        try {

            const {error} = await supabase
                .from('pitchers')
                .insert({
                    user_id: currentUser.id,
                    ...data,
                    pitcherGamesPlayed: 0,
                    pitcherInningsPitched: 0,
                    pitcherWins: 0,
                    pitcherLosses: 0,
                    pitcherSaves: 0,
                    pitcherStrikeouts: 0,
                    pitcherWalks: 0,
                    pitcherHits: 0,
                    pitcherEarnedRuns: 0,
                    pitcherHomeruns: 0,
                    pitcherGamesStarted: 0,
                    pitcherCompleteGames: 0,
                    pitcherShutouts: 0,
                    pitcherQualityStarts: 0,
                    playerNameCardType: player,
                    user_email: current_user_email,
                    year: year
                })

            if (error) {
                setFetchError(error);
            }

            await Swal.fire({
                title: "Pitcher added successfully",
                icon: "success",
                showCancelButton: true,
                confirmButtonText: "OK",
                cancelButtonText: "Back to previous page",
            }).then((result) => {
                if (result.isConfirmed) {
                    // handle OK button click
                    setData({});
                    setPlayer({});
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    // handle "Back to previous page" button click
                    window.history.back();
                }
            });

        } catch (err) {
            console.log(err)
        }

    }

    return (
        <div className="new">
            <Sidebar/>
            <div className="newContainer">
                <div className="top">
                    <h1>{title}</h1>
                </div>
                <div className="bottom">
                    <div className="content">
                        {fetchError && (<p>{fetchError}</p>)}
                        <form onSubmit={handleAdd}>
                            {inputs.map((input) => (
                                <div className="formInput" key={input.id}>
                                    <label htmlFor={input.id}>{input.label}</label>
                                    {input.type === "select" ? (
                                        <select
                                            id={input.id}
                                            onChange={handleInput}
                                            value={data[input.id]}  // Bind the value to state
                                            required
                                        >
                                            {input.options.map((option) => (
                                                <option
                                                    key={option.value}
                                                    value={option.value}
                                                >
                                                    {option.label}
                                                </option>
                                            ))}
                                        </select>
                                    ) : (
                                        <input
                                            id={input.id}
                                            type={input.type}
                                            placeholder={input.placeholder}
                                            onChange={handleInput}
                                            value={data[input.id] || ''}  // Bind the value to state
                                            required
                                        />
                                    )}
                                </div>
                            ))}
                            {
                                current_user_email === 'baseballstatstrackertestuser@gmail.com'
                                    ? (
                                        <h3>You cannot submit that content</h3>
                                    ) : (
                                        <button type="submit">Send</button>
                                    )
                            }
                        </form>
                    </div>
                </div>
                <Footer/>
            </div>
        </div>
    );
}

export default NewPitcher
