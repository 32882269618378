import './teamGameStats.scss';

import Sidebar from "../../components/sidebar/sidebar";
import { Link, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { gameModeGameLog } from "../../datatablesource";
import { DataGrid } from "@mui/x-data-grid";
import supabase from "../../config/supabaseClient";
import Footer from "../../components/footer/footer";
import { Paper } from "@mui/material";

const TeamGameStats = () => {

    const {year} = useParams();

    const {currentUser} = useContext(AuthContext);
    const current_user_email = currentUser.email

    const [soloRankedData, setSoloRankedData] = useState([]);
    const [soloRankedError, setSoloRankedError] = useState("");

    const [coopRankedData, setCoOpRankedData] = useState([]);
    const [coopRankedError, setCoOpRankedError] = useState("");

    const [brRankedData, setBRRankedData] = useState([]);
    const [brRankedError, setBRRankedError] = useState("");

    const [customLeagueData, setCustomLeaguedData] = useState([]);
    const [customLeagueError, setCustomLeagueError] = useState("");

    useEffect(() => {

        const fetchGameLogData = async () => {

            const {data: soloRankedData, error: soloRankedError} = await supabase
                .from('teamGameStatLog')
                .select()
                .eq("year", year)
                .eq("user_email", current_user_email)
                .eq("gameModeType", "soloRanked")
                .order('gameNumber', {ascending: false});

            if (soloRankedError) {
                console.log("Error fetching solo ranked data:", soloRankedError);
                setSoloRankedError(soloRankedError)
            } else {
                console.log("Solo Ranked data:", soloRankedData);
                setSoloRankedData(soloRankedData)
            } // end of setSoloRankedData

            const {data: coopRankedData, error: coopRankedError} = await supabase
                .from('teamGameStatLog')
                .select()
                .eq("year", year)
                .eq("user_email", current_user_email)
                .eq("gameModeType", "coopRanked")

            if (coopRankedError) {
                console.log("Error fetching co-op ranked data:", coopRankedError);
                setCoOpRankedError(coopRankedError)
            } else {
                console.log("Co-Op Ranked data:", coopRankedData);
                setCoOpRankedData(coopRankedData)
            } // end of setCoOpRankedData

            const {data: brRankedData, error: brRankedError} = await supabase
                .from('teamGameStatLog')
                .select()
                .eq("year", year)
                .eq("user_email", current_user_email)
                .eq("gameModeType", "battleRoyale")

            if (brRankedError) {
                console.log("Error fetching BR ranked data:", brRankedError);
                setBRRankedError(brRankedError)
            } else {
                console.log("BR Ranked data:", brRankedData);
                setBRRankedData(brRankedData)
            } // end of setBRRankedData

            const {data: customLeagueData, error: customLeagueError} = await supabase
                .from('teamGameStatLog')
                .select()
                .eq("year", year)
                .eq("user_email", current_user_email)
                .eq("gameModeType", "customLeague")

            if (customLeagueError) {
                console.log("Error fetching Custom League ranked data:", customLeagueError);
                setCustomLeagueError(customLeagueError)
            } else {
                console.log("Custom League Ranked data:", customLeagueData);
                setCustomLeaguedData(customLeagueData)
            } // end of setCustomLeaguedData

        }

        fetchGameLogData();

    }, [current_user_email, year])


    return (
        <div className="teamGameStats">
            <Sidebar/>
            <div className="teamGameStatsContainer">
                <div className="teamGameStatsContent">

                    <div className="top">
                        <h1>Online Game Log</h1>
                        {
                            current_user_email === 'baseballstatstrackertestuser@gmail.com'
                                ? (
                                    <></>
                                ) : (
                                    <Link to={`/${year}/teamGameStats/new`} className="link">
                                        Add New Game Log For Any Mode
                                    </Link>
                                )
                        }
                    </div>

                    <div className="tableContainer">

                        {/* one */}
                        <h2>Solo Ranked Game Log</h2>
                        {soloRankedError && (<p>{soloRankedError}</p>)}
                        <Paper className="tableWrapper">
                            <DataGrid
                                sortModel={[
                                    {
                                        field: 'gameNumber',
                                        sort: 'desc', // or 'desc' for descending
                                    },
                                ]}
                                rows={soloRankedData}
                                columns={gameModeGameLog}
                                pageSize={8}
                                rowsPerPageOptions={[8]}
                                checkboxSelection
                            />
                        </Paper>

                        {/* two */}
                        <h2>Co-Op Ranked Game Log</h2>
                        {coopRankedError && (<p>{coopRankedError}</p>)}
                        <Paper className="tableWrapper">
                            <DataGrid
                                rows={coopRankedData}
                                columns={gameModeGameLog}
                                pageSize={8}
                                rowsPerPageOptions={[8]}
                                checkboxSelection
                            />
                        </Paper>

                        {/* Three */}
                        <h2>Battle Royale Game Log</h2>
                        {brRankedError && (<p>{brRankedError}</p>)}
                        <Paper className="tableWrapper">
                            <DataGrid
                                rows={brRankedData}
                                columns={gameModeGameLog}
                                pageSize={8}
                                rowsPerPageOptions={[8]}
                                checkboxSelection
                            />
                        </Paper>


                        {/* Four */}
                        <h2>Custom League Game Log</h2>
                        {customLeagueError && (<p>{customLeagueError}</p>)}
                        <Paper className="tableWrapper">
                            <DataGrid
                                rows={customLeagueData}
                                columns={gameModeGameLog}
                                pageSize={8}
                                rowsPerPageOptions={[8]}
                                checkboxSelection
                            />
                        </Paper>

                    </div>

                </div>
                <Footer/>
            </div>
        </div>
    );
}

export default TeamGameStats