export const addHitterGameStatsInput = [
    {
        id: "hitterGamesPlayed",
        label: "Games Played",
        type: "number",
        placeholder: "1",
    },
    {
        id: "hitterAppearances",
        label: "Plate Appearances",
        type: "number",
        placeholder: "4",
    },
    {
        id: "hitterAtBats",
        label: "At Bats",
        type: "number",
        placeholder: "4",
    },
    {
        id: "hitterHits",
        label: "Hits",
        type: "number",
        placeholder: "4",
    },
    {
        id: "hitterSingles",
        label: "Singles",
        type: "number",
        placeholder: "1",
    },
    {
        id: "hitterDoubles",
        label: "2B",
        type: "number",
        placeholder: "1",
    },
    {
        id: "hitterTriples",
        label: "3B",
        type: "number",
        placeholder: "1",
    },
    {
        id: "hitterHomeRuns",
        label: "HR",
        type: "number",
        placeholder: "1",
    },
    {
        id: "hitterRunsBattedIn",
        label: "RBI",
        type: "number",
        placeholder: "5",
    },
    {
        id: "hitterWalks",
        label: "BB",
        type: "number",
        placeholder: "0",
    },
    {
        id: "hitterStrikeouts",
        label: "SO",
        type: "number",
        placeholder: "0",
    },
    {
        id: "gameModeType",
        label: "Game Mode Type",
        type: "select",
        options: [
            {
                label: "Select One",
                value: "SelectOne"
            },
            {
                label: "Solo Ranked",
                value: "soloRanked"
            },
            {
                label: "Co-Op Ranked",
                value: "coopRanked"
            },
            {
                label: "Battle Royale",
                value: "battleRoyale"
            },
            {
                label: "Custom League",
                value: "customLeague"
            },
        ],
        defaultValue: "SelectOne",
    },
    {
        id: "hitterType",
        label: "Hitter Type",
        type: "string",
        placeholder: "Just type Hitter so the above data gets saved",
    },
]

export const addPitcherGameStatsInput = [
    {
        id: "pitcherGamesPlayed",
        label: "Games Played",
        type: "number",
        placeholder: "1",
    },
    {
        id: "pitcherInningsPitched",
        label: "Innings Pitched",
        type: "number",
        placeholder: "7",
    },
    {
        id: "pitcherWins",
        label: "Win?",
        type: "number",
        placeholder: "1",
    },
    {
        id: "pitcherLosses",
        label: "Lose?",
        type: "number",
        placeholder: "0",
    },
    {
        id: "pitcherSaves",
        label: "Save",
        type: "number",
        placeholder: "1",
    },
    {
        id: "pitcherStrikeouts",
        label: "Strikeouts?",
        type: "number",
        placeholder: "8",
    },
    {
        id: "pitcherWalks",
        label: "Walks Allowed",
        type: "number",
        placeholder: "4",
    },
    {
        id: "pitcherHits",
        label: "Hits Allowed",
        type: "number",
        placeholder: "7",
    },
    {
        id: "pitcherEarnedRuns",
        label: "Earned Runs Allowed",
        type: "number",
        placeholder: "3",
    },
    {
        id: "pitcherHomeruns",
        label: "Homeruns Allowed",
        type: "number",
        placeholder: "0",
    },
    {
        id: "pitcherGamesStarted",
        label: "Game Started",
        type: "number",
        placeholder: "1",
    },
    {
        id: "pitcherCompleteGames",
        label: "Complete Game?",
        type: "number",
        placeholder: "0",
    },
    {
        id: "pitcherShutouts",
        label: "Shutout?",
        type: "number",
        placeholder: "0",
    },
    {
        id: "pitcherQualityStarts",
        label: "Quality Start?",
        type: "number",
        placeholder: "1",
    },
    {
        id: "gameModeType",
        label: "Game Mode Type",
        type: "select",
        options: [
            {
                label: "Select One",
                value: "SelectOne"
            },
            {
                label: "Solo Ranked",
                value: "soloRanked"
            },
            {
                label: "Co-Op Ranked",
                value: "coopRanked"
            },
            {
                label: "Battle Royale",
                value: "battleRoyale"
            },
            {
                label: "Custom League",
                value: "customLeague"
            },
        ],
        defaultValue: "SelectOne",
    },
    {
        id: "pitcherType",
        label: "Pitcher Type",
        type: "string",
        placeholder: "Just type Pitcher so the above data gets saved",
    },
]

export const addNewTeamGameStats = [
    {
        id: "gameNumber",
        label: "Game Number",
        type: "string",
        placeholder: "10",
    },
    {
        id: "timestamp",
        label: "When did you play the game?",
        type: "date",
        placeholder: "4/1/23",
    },
    {
        id: "gameLocation",
        label: "Home or Away",
        type: "string",
        placeholder: "Home",
    },
    {
        id: "gameTime",
        label: "Day or Night",
        type: "string",
        placeholder: "Night",
    },
    {
        id: "ratingBefore",
        label: "Rating Before",
        type: "string",
        placeholder: "469",
    },
    {
        id: "ratingAfter",
        label: "Rating After",
        type: "string",
        placeholder: "500",
    },
    {
        id: "gameRunsFor",
        label: "Runs You Scored",
        type: "string",
        placeholder: "5",
    },
    {
        id: "gameRunsAgainst",
        label: "Runs Your Opponent Scored",
        type: "string",
        placeholder: "0",
    },
    {
        id: "hitsFor",
        label: "How many hits did you get?",
        type: "string",
        placeholder: "10",
    },
    {
        id: "hitsAgainst",
        label: "How many hits did your opponent get?",
        type: "string",
        placeholder: "0",
    },
    {
        id: "gameWinCounter",
        label: "Did you win?",
        type: "string",
        placeholder: "1",
    },
    {
        id: "gameLossCounter",
        label: "Did you lose?",
        type: "string",
        placeholder: "0",
    },
    {
        id: "seasonNum",
        label: "What season was this played in?",
        type: "string",
        placeholder: "Season 3",
    },
    {
        id: "rageQuit",
        label: "Did you or your opponent rage quit?",
        type: "string",
        placeholder: "They raged quit",
    },
    {
        id: "awayWinCounter",
        label: "Put 1 if this game was an AWAY win",
        type: "int",
        placeholder: "1",
    },
    {
        id: "awayLossCounter",
        label: "Put 1 if this game was an AWAY loss",
        type: "int",
        placeholder: "1",
    },
    {
        id: "homeWinCounter",
        label: "Put 1 if this game was a HOME win",
        type: "int",
        placeholder: "1",
    },
    {
        id: "homeLossCounter",
        label: "Put 1 if this game was a HOME loss",
        type: "int",
        placeholder: "1",
    },
    {
        id: "dayWinCounter",
        label: "Put 1 if this game was a DAY win",
        type: "int",
        placeholder: "1",
    },
    {
        id: "dayLossCounter",
        label: "Put 1 if this game was a DAY loss",
        type: "int",
        placeholder: "1",
    },
    {
        id: "nightWinCounter",
        label: "Put 1 if this game was a NIGHT win",
        type: "int",
        placeholder: "1",
    },
    {
        id: "nightLossCounter",
        label: "Put 1 if this game was a NIGHT loss",
        type: "int",
        placeholder: "1",
    },
    {
        id: "gameModeType",
        label: "Game Mode Type",
        type: "select",
        options: [
            {
                label: "Select One",
                value: "SelectOne"
            },
            {
                label: "Solo Ranked",
                value: "soloRanked"
            },
            {
                label: "Co-Op Ranked",
                value: "coopRanked"
            },
            {
                label: "Battle Royale",
                value: "battleRoyale"
            },
            {
                label: "Custom League",
                value: "customLeague"
            },
        ],
        defaultValue: "SelectOne",
    },
    {
        id: "text",
        label: "Text",
        type: "string",
        placeholder: "Type To Make Sure Everything Is In",
    },
]


export const addHitterInputs = [
    {
        id: "hitterName",
        label: "Player Name",
        type: "text",
        placeholder: "Corey Seager",
    },
    {
        id: "cardType",
        label: "Card Type",
        type: "select",
        options: [
            {
                label: "Select One",
                value: "SelectOne"
            },
            {
                label: "Live",
                value: "Live"
            },
            {
                label: "Rookie",
                value: "Rookie"
            },
            {
                label: "Breakout",
                value: "Breakout"
            },
            {
                label: "Veteran",
                value: "Veteran"
            },
            {
                label: "All Star",
                value: "AllStar"
            },
            {
                label: "Awards",
                value: "Awards"
            },
            {
                label: "Postseason",
                value: "Postseason"
            },
            {
                label: "Monthly Awards",
                value: "MonthlyAwards"
            },
            {
                label: "Future Stars",
                value: "FutureStars"
            },
            {
                label: "Signature",
                value: "Signature"
            },
            {
                label: "Prime",
                value: "Prime"
            },
            {
                label: "Topps Now",
                value: "ToppsNow"
            },
            {
                label: "Finest",
                value: "Finest"
            },
            {
                label: "2nd Half Heroes",
                value: "2ndHalfHeroes"
            },
            {
                label: "Milestone",
                value: "Milestone"
            },
            {
                label: "Captain",
                value: "Captain"
            },
            {
                label: "Charisma",
                value: "Charisma"
            },
            {
                label: "WBC",
                value: "WBC"
            },
            {
                label: "All Star Game",
                value: "AllStarGame"
            },
            {
                label: "HRD",
                value: "HRD"
            },
            {
                label: "Incognito",
                value: "Incognito"
            },
            {
                label: "Kaiju",
                value: "Kaiju"
            },
            {
                label: "Jin Kim",
                value: "JinKim"
            },
            {
                label: "2023 All Star",
                value: "2023AllStar"
            },
            {
                label: "2023 Draft",
                value: "2023Draft"
            },
            {
                label: "2023 HRD",
                value: "2023HRD"
            },
            {
                label: "Snapshot",
                value: "Snapshot"
            },
            {
                label: "98 Race",
                value: "98Race"
            },
            {
                label: "2023 Postseason",
                value: "2023Postseason"
            },
            {
                label: "2023 Finest",
                value: "2023Finest"
            },
        ],
        defaultValue: "SelectOne",
    },
    {
        id: "hitterOverall",
        label: "Base Overall (No Parallel)",
        type: "number",
        placeholder: "90",
    },
    {
        id: "hitterPosition",
        label: "Position",
        type: "select",
        options: [
            {
                label: "Select One",
                value: "SelectOne"
            },
            {
                label: "DH",
                value: "DH"
            },
            {
                label: "C",
                value: "C"
            },
            {
                label: "1B",
                value: "1B"
            },
            {
                label: "2B",
                value: "2B"
            },
            {
                label: "3B",
                value: "3B"
            },
            {
                label: "SS",
                value: "SS"
            },
            {
                label: "LF",
                value: "LF"
            },
            {
                label: "CF",
                value: "CF"
            },
            {
                label: "RF",
                value: "RF"
            },
        ],
        defaultValue: "SelectOne",
    },
    {
        id: "hitterType",
        label: "Position Type",
        type: "text",
        placeholder: "Hitter",
    },
];

export const addNewPitcherInputs = [
    {
        id: "pitcherName",
        label: "Player Name",
        type: "text",
        placeholder: "Randy Johnson",
    },
    {
        id: "cardType",
        label: "Card Type",
        type: "select",
        options: [
            {
                label: "Select One",
                value: "SelectOne"
            },
            {
                label: "Live",
                value: "Live"
            },
            {
                label: "Rookie",
                value: "Rookie"
            },
            {
                label: "Breakout",
                value: "Breakout"
            },
            {
                label: "Veteran",
                value: "Veteran"
            },
            {
                label: "All Star",
                value: "AllStar"
            },
            {
                label: "Awards",
                value: "Awards"
            },
            {
                label: "Postseason",
                value: "Postseason"
            },
            {
                label: "Monthly Awards",
                value: "MonthlyAwards"
            },
            {
                label: "Future Stars",
                value: "FutureStars"
            },
            {
                label: "Signature",
                value: "Signature"
            },
            {
                label: "Prime",
                value: "Prime"
            },
            {
                label: "Topps Now",
                value: "ToppsNow"
            },
            {
                label: "Finest",
                value: "Finest"
            },
            {
                label: "2nd Half Heroes",
                value: "2ndHalfHeroes"
            },
            {
                label: "Milestone",
                value: "Milestone"
            },
            {
                label: "Captain",
                value: "Captain"
            },
            {
                label: "Charisma",
                value: "Charisma"
            },
            {
                label: "WBC",
                value: "WBC"
            },
            {
                label: "All Star Game",
                value: "AllStarGame"
            },
            {
                label: "HRD",
                value: "HRD"
            },
            {
                label: "Incognito",
                value: "Incognito"
            },
            {
                label: "Kaiju",
                value: "Kaiju"
            },
            {
                label: "Jin Kim",
                value: "JinKim"
            },
            {
                label: "2023 All Star",
                value: "2023AllStar"
            },
            {
                label: "2023 Draft",
                value: "2023Draft"
            },
            {
                label: "2023 HRD",
                value: "2023HRD"
            },
            {
                label: "Snapshot",
                value: "Snapshot"
            },
            {
                label: "98 Race",
                value: "98Race"
            },
            {
                label: "2023 Postseason",
                value: "2023 Postseason"
            },
            {
                label: "2023 Finest",
                value: "2023Finest"
            },
        ],
        defaultValue: "SelectOne",
    },
    {
        id: "pitcherOverall",
        label: "Base Overall (No Parallel)",
        type: "number",
        placeholder: "99",
    },
    {
        id: "pitcherPosition",
        label: "Position",
        type: "select",
        options: [
            {
                label: "Select One",
                value: "SelectOne"
            },
            {
                label: "SP",
                value: "SP"
            },
            {
                label: "RP",
                value: "RP"
            },
            {
                label: "CP",
                value: "CP"
            },
        ],
        defaultValue: "SelectOne",
    },
    {
        id: "positionType",
        label: "Position Type",
        type: "text",
        placeholder: "Pitcher",
    },
]

export const addBulkDataHitterGameStatsInput = [
    {
        id: "hitterGamesPlayed",
        label: "GP",
        type: "number",
        placeholder: "",
    },
    {
        id: "hitterAppearances",
        label: "PAs",
        type: "number",
        placeholder: "",
    },
    {
        id: "hitterAtBats",
        label: "ABs",
        type: "number",
        placeholder: "",
    },
    {
        id: "hitterHits",
        label: "Hits",
        type: "number",
        placeholder: "",
    },
    {
        id: "hitterSingles",
        label: "1B",
        type: "number",
        placeholder: "",
    },
    {
        id: "hitterDoubles",
        label: "2B",
        type: "number",
        placeholder: "",
    },
    {
        id: "hitterTriples",
        label: "3B",
        type: "number",
        placeholder: "",
    },
    {
        id: "hitterHomeRuns",
        label: "HR",
        type: "number",
        placeholder: "",
    },
    {
        id: "hitterRunsBattedIn",
        label: "RBI",
        type: "number",
        placeholder: "",
    },
    {
        id: "hitterWalks",
        label: "BB",
        type: "number",
        placeholder: "",
    },
    {
        id: "hitterStrikeouts",
        label: "SO",
        type: "number",
        placeholder: "",
    },
    {
        id: "gameModeType",
        label: "Game Mode Type",
        type: "select",
        options: [
            {
                label: "Select One",
                value: "SelectOne"
            },
            {
                label: "Solo Ranked",
                value: "soloRanked"
            },
            {
                label: "Co-Op Ranked",
                value: "coopRanked"
            },
            {
                label: "Battle Royale",
                value: "battleRoyale"
            },
            {
                label: "Custom League",
                value: "customLeague"
            },
        ],
        defaultValue: "SelectOne",
    },
];


export const addBulkDataPitcherGameStatsInput = [
    {
        id: "pitcherGamesPlayed",
        label: "GP",
        type: "number",
        placeholder: "",
    },
    {
        id: "pitcherInningsPitched",
        label: "IP",
        type: "number",
        placeholder: "",
    },
    {
        id: "pitcherWins",
        label: "Win?",
        type: "number",
        placeholder: "",
    },
    {
        id: "pitcherLosses",
        label: "Lose?",
        type: "number",
        placeholder: "",
    },
    {
        id: "pitcherSaves",
        label: "Save",
        type: "number",
        placeholder: "",
    },
    {
        id: "pitcherStrikeouts",
        label: "SO",
        type: "number",
        placeholder: "",
    },
    {
        id: "pitcherWalks",
        label: "BB",
        type: "number",
        placeholder: "",
    },
    {
        id: "pitcherHits",
        label: "Hits",
        type: "number",
        placeholder: "",
    },
    {
        id: "pitcherEarnedRuns",
        label: "ER",
        type: "number",
        placeholder: "",
    },
    {
        id: "pitcherHomeruns",
        label: "HR",
        type: "number",
        placeholder: "",
    },
    {
        id: "pitcherGamesStarted",
        label: "GS",
        type: "number",
        placeholder: "",
    },
    {
        id: "pitcherCompleteGames",
        label: "CG",
        type: "number",
        placeholder: "",
    },
    {
        id: "pitcherShutouts",
        label: "Shutout?",
        type: "number",
        placeholder: "",
    },
    {
        id: "pitcherQualityStarts",
        label: "QS?",
        type: "number",
        placeholder: "",
    },
    {
        id: "gameModeType",
        label: "Game Mode Type",
        type: "select",
        options: [
            {
                label: "Select One",
                value: "SelectOne"
            },
            {
                label: "Solo Ranked",
                value: "soloRanked"
            },
            {
                label: "Co-Op Ranked",
                value: "coopRanked"
            },
            {
                label: "Battle Royale",
                value: "battleRoyale"
            },
            {
                label: "Custom League",
                value: "customLeague"
            },
        ],
        defaultValue: "SelectOne",
    },
];