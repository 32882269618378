import './auth.scss'
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { useContext, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

import supabase from "../../config/supabaseClient"

import LockOpenIcon from '@mui/icons-material/LockOpen';
import { Grid } from "@mui/material";

const Login = () => {

    const [theError, setError] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const navigate = useNavigate();

    const {dispatch} = useContext(AuthContext);

    const handleLogin = async (e) => {

        e.preventDefault();

        try {

            const {data, error} = await supabase.auth.signInWithPassword({
                email: email,
                password: password,
            })

            // console.log(data)
            if (error) {
                console.log('Some error occurred' + error)
                setError(error)
            }

            const userInfo = data.user

            if (data.user.aud === "authenticated") {

                dispatch({type: "LOGIN", payload: userInfo})

                navigate("/welcome");

            }

        } catch (error) {
            console.log(error)
            setError(error)
        }

    }

    return (

        <div className="auth">
            <div className="overlay"></div>
            <video
                src="https://apk-spaces-bucket.sfo3.cdn.digitaloceanspaces.com/MLBTS-Stats-Project%2FLoginPage-HR-Montage.mp4"
                autoPlay
                muted
                loop
                playsInline
            />

            <div className="content">
                <div className="title">
                    <span>
                        <LockOpenIcon fontSize="large"/>
                    </span>
                    <h1>Sign In</h1>
                </div>
                <form onSubmit={handleLogin}>
                    <input type="email" placeholder="email" onChange={e => setEmail(e.target.value)}/>
                    <input type="password" placeholder="password" onChange={e => setPassword(e.target.value)}/>
                    <button type="submit">Log In</button>
                    {theError &&
                        <Grid
                            container
                            className="errorMessage"
                        >
                            <Grid item>
                                Wrong Email or Password
                            </Grid>
                            <Grid item>
                                <SentimentVeryDissatisfiedIcon fontSize="large" className="icon"/>
                            </Grid>
                        </Grid>
                    }
                </form>
                <div className="bottom">
                    If you do not have an account ->
                    <Link className="link" to="/signup">
                        Sign Up
                    </Link>
                </div>
            </div>

        </div>

    );
}

export default Login