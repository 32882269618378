import './footer.scss'
import FavoriteIcon from '@mui/icons-material/Favorite';
import CopyrightIcon from '@mui/icons-material/Copyright';
import { Grid } from "@mui/material";
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { Link, useParams } from "react-router-dom";
import Divider from "@mui/material/Divider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Footer = () => {

    const {year} = useParams();

    return (
        <div className="footerContent">
            <Divider sx={{
                mb: 2
            }}/>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Grid item>
                    Made With
                </Grid>
                <Grid item>
                    <FavoriteIcon className="heart"/>
                </Grid>
                <Grid item>
                    by Alex Kenny
                </Grid>
            </Grid>

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Grid item sx={{marginRight: 1}}>
                    To Contact For Anything or Bugs,
                </Grid>

                <Grid item>
                    Discord: Redpin14#8626
                </Grid>
            </Grid>


            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Grid item marginRight={1}>
                    Patch Notes
                </Grid>
                <Link to={`/${year}/patch-notes`} style={{textDecoration: "none"}}>
                    <Grid item>
                        <TextSnippetIcon className="patchNotes" fontSize="large"/>
                    </Grid>
                </Link>
            </Grid>

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Grid item>
                    V 1.5.1
                </Grid>
            </Grid>

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Grid item>
                    Copyright
                </Grid>
                <Grid item>
                    <CopyrightIcon className="icon"/>
                </Grid>
                <Grid item>
                    2023
                </Grid>
            </Grid>

        </div>
    );

}

export default Footer;
