import "./widget.scss";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import { useContext, useEffect, useState } from "react";
import supabase from "../../config/supabaseClient";
import { AuthContext } from "../../context/AuthContext";
import { useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";

const Widget = ({type}) => {

    const {year} = useParams();

    // fetch data
    const [fetchError, setFetchError] = useState("")
    const [homerunAmount, setHomerunAmount] = useState("")
    const [strikeoutsAmount, setStrikeoutsAmount] = useState("")
    const [pitchersERA, setPitchersERA] = useState("")
    const [hittersAVG, setHittersAVG] = useState("")
    const [teamRecord, setTeamRecord] = useState("")
    const [teamRunsForAgainst, setTeamRunsForAgainst] = useState("")
    const [teamHitsForAgainst, setTeamHitsForAgainst] = useState("")
    const [teamDayRecord, setTeamDayRecord] = useState("")
    const [teamNightRecord, setTeamNightRecord] = useState("")
    const [teamHomeRecord, setTeamHomeRecord] = useState("")
    const [teamAwayRecord, setTeamAwayRecord] = useState("")
    const [hitsForAgainstPerGame, setHitsForAgainstPerGame] = useState("")
    const [runsForAgainstPerGame, setRunsForAgainstPerGame] = useState("")

    const {currentUser} = useContext(AuthContext);
    const current_user_email = currentUser.email

    const fetchTeamData = async () => {

        const {data, error} = await supabase
            .from('teamGameStats')
            .select()
            .eq("year", year)
            .eq("user_email", current_user_email)

        if (error) {
            setFetchError('Some error occurred')
            console.log(error)
        }
        if (data) {
            setFetchError(null)
        }

        for (let i = 0; i < data.length; i++) {

            // console.log(data[i])

            const wins = Number(data[i].gameWinCounter)
            const losses = Number(data[i].gameLossCounter)
            let record = wins + " - " + losses
            setTeamRecord(record)

            const games = wins + losses;

            const dayWins = Number(data[i].dayWinCounter)
            const dayLosses = Number(data[i].dayLossCounter)
            let dayRecord = dayWins + " - " + dayLosses
            setTeamDayRecord(dayRecord)

            const nightWins = Number(data[i].nightWinCounter)
            const nightLosses = Number(data[i].nightLossCounter)
            let nightRecord = nightWins + " - " + nightLosses
            setTeamNightRecord(nightRecord)

            const homeWins = Number(data[i].homeWinCounter)
            const homeLosses = Number(data[i].homeLossCounter)
            let homeRecord = homeWins + " - " + homeLosses
            setTeamHomeRecord(homeRecord)

            const awayWins = Number(data[i].awayWinCounter)
            const awayLosses = Number(data[i].awayLossCounter)
            let awayRecord = awayWins + " - " + awayLosses
            setTeamAwayRecord(awayRecord)

            const runsFor = Number(data[i].gameRunsFor)
            const runsAgainst = Number(data[i].gameRunsAgainst)
            let runsForAgainst = runsFor + " - " + runsAgainst
            setTeamRunsForAgainst(runsForAgainst)

            const hitsFor = Number(data[i].hitsFor)
            const hitsAgainst = Number(data[i].hitsAgainst)
            let hitsForAgainst = hitsFor + " - " + hitsAgainst
            setTeamHitsForAgainst(hitsForAgainst)

            // hits for and against per game
            const hitsForPerGame = Math.ceil(hitsFor / games)
            const hitsAgainstPerGame = Math.ceil(hitsAgainst / games)
            const hitsForAgainstPerGame = hitsForPerGame + " - " + hitsAgainstPerGame
            setHitsForAgainstPerGame(hitsForAgainstPerGame)

            // runs for and against per game
            const runsForPerGame = Math.ceil(runsFor / games)
            const runsAgainstPerGame = Math.ceil(runsAgainst / games)
            const runsForAgainstPerGame = runsForPerGame + " - " + runsAgainstPerGame
            setRunsForAgainstPerGame(runsForAgainstPerGame)

        }

    }

    const fetchHitterData = async () => {

        const {data, error} = await supabase
            .from('hittersGameStats')
            .select()
            .eq("user_email", current_user_email)
            .eq("year", year)

        if (error) {
            setFetchError('Some error occurred')
            console.log(error)
        }
        if (data) {
            setFetchError(null)
        }

        const sumOfHomerunsHit = data.reduce((accumulator, currentValue) => accumulator + Number(currentValue.hitterHomeRuns), 0,);
        setHomerunAmount(sumOfHomerunsHit)

        const sumOfHits = data.reduce((accumulator, currentValue) => accumulator + Number(currentValue.hitterHits), 0,);
        const sumOfAtBats = data.reduce((accumulator, currentValue) => accumulator + Number(currentValue.hitterAtBats), 0,);

        const hitterAverage = (sumOfHits / sumOfAtBats).toFixed(3)
        setHittersAVG(hitterAverage)

    }

    const fetchPitcherData = async () => {

        const {data, error} = await supabase
            .from('pitchersGameStats')
            .select()
            .eq("user_email", current_user_email)
            .eq("year", year)

        if (error) {
            setFetchError('Some error occurred')
            console.log(error)
        }
        if (data) {
            setFetchError(null)
        }

        // console.log(data)

        const sumOfStrikeouts = data.reduce((accumulator, currentValue) => accumulator + Number(currentValue.pitcherStrikeouts), 0,);
        setStrikeoutsAmount(sumOfStrikeouts)

        const earnedRuns = data.reduce((accumulator, currentValue) => accumulator + Number(currentValue.pitcherEarnedRuns), 0,);
        const inningsPitched = data.reduce((accumulator, currentValue) => accumulator + Number(currentValue.pitcherInningsPitched), 0,);

        const teamERA = (9 * (earnedRuns / inningsPitched)).toFixed(2)
        setPitchersERA(teamERA)

    }

    useEffect(() => {

        // console.log("rendered")
        fetchTeamData();
        fetchHitterData();
        fetchPitcherData();

    }, [current_user_email, fetchHitterData, fetchPitcherData, fetchTeamData, year])

    let data;

    switch (type) {
        case 'record':
            data = {
                title: "Record", amount: teamRecord, icon: (<PersonOutlinedIcon
                    className="icon"
                    style={{
                        color: "crimson", backgroundColor: "rgba(255, 0, 0, 0.2)",
                    }}
                />),
            };
            break;
        case 'runsForAgainst':
            data = {
                title: "Runs For and Against", amount: teamRunsForAgainst, icon: (<ShoppingCartOutlinedIcon
                    className="icon"
                    style={{
                        backgroundColor: "rgba(218, 165, 32, 0.2)", color: "goldenrod",
                    }}
                />),
            };
            break;
        case 'hitsForAgainst':
            data = {
                title: "Hits For and Against", amount: teamHitsForAgainst, icon: (<MonetizationOnOutlinedIcon
                    className="icon"
                    style={{backgroundColor: "rgba(0, 128, 0, 0.2)", color: "green"}}
                />),
            };
            break;
        case 'dayRecord':
            data = {
                title: "Day Game Record", amount: teamDayRecord, icon: (<AccountBalanceWalletOutlinedIcon
                    className="icon"
                    style={{
                        backgroundColor: "rgba(128, 0, 128, 0.2)", color: "purple",
                    }}
                />),
            };
            break;
        case 'nightRecord':
            data = {
                title: "Night Game Record", amount: teamNightRecord, icon: (<AccountBalanceWalletOutlinedIcon
                    className="icon"
                    style={{
                        backgroundColor: "rgba(128, 0, 128, 0.2)", color: "purple",
                    }}
                />),
            };
            break;
        case 'homeRecord':
            data = {
                title: "Home Game Record", amount: teamHomeRecord, icon: (<AccountBalanceWalletOutlinedIcon
                    className="icon"
                    style={{
                        backgroundColor: "rgba(128, 0, 128, 0.2)", color: "purple",
                    }}
                />),
            };
            break;
        case 'awayRecord':
            data = {
                title: "Away Game Record", amount: teamAwayRecord, icon: (<AccountBalanceWalletOutlinedIcon
                    className="icon"
                    style={{
                        backgroundColor: "rgba(128, 0, 128, 0.2)", color: "purple",
                    }}
                />),
            };
            break;
        case 'homeruns':
            data = {
                title: "Total Homeruns Hit", amount: homerunAmount, icon: (<AccountBalanceWalletOutlinedIcon
                    className="icon"
                    style={{
                        backgroundColor: "rgba(128, 0, 128, 0.2)", color: "purple",
                    }}
                />),
            };
            break;
        case 'era':
            data = {
                title: "Team ERA", amount: pitchersERA, icon: (<AccountBalanceWalletOutlinedIcon
                    className="icon"
                    style={{
                        backgroundColor: "rgba(128, 0, 128, 0.2)", color: "purple",
                    }}
                />),
            };
            break;
        case 'avg':
            data = {
                title: "Team AVG", amount: hittersAVG, icon: (<AccountBalanceWalletOutlinedIcon
                    className="icon"
                    style={{
                        backgroundColor: "rgba(251, 192, 147, 0.2)", color: "orange",
                    }}
                />),
            };
            break;
        case 'strikeouts':
            data = {
                title: "Team Strikeouts", amount: strikeoutsAmount, icon: (<AccountBalanceWalletOutlinedIcon
                    className="icon"
                    style={{
                        backgroundColor: "rgba(128, 0, 128, 0.2)", color: "purple",
                    }}
                />),
            };
            break;
        case 'hitsForAgainstPerGame':
            data = {
                title: "Hits For Against / Game", amount: hitsForAgainstPerGame, icon: (<AccountBalanceWalletOutlinedIcon
                    className="icon"
                    style={{
                        backgroundColor: "rgba(128, 0, 128, 0.2)", color: "purple",
                    }}
                />),
            };
            break;
        case 'runsForAgainstPerGame':
            data = {
                title: "Runs For Against / Game", amount: runsForAgainstPerGame, icon: (<AccountBalanceWalletOutlinedIcon
                    className="icon"
                    style={{
                        backgroundColor: "rgba(128, 0, 128, 0.2)", color: "purple",
                    }}
                />),
            };
            break;
        default:
            break;
    }

    return (
        <div className="widget">
            {fetchError &&
                <Grid
                    container
                    className="errorMessage"
                >
                    <Grid item>
                        Something went wrong.
                    </Grid>
                    <Grid item>
                        <SentimentVeryDissatisfiedIcon fontSize="large" className="icon"/>
                    </Grid>
                </Grid>
            }
            <div className="center">
                    <span className="title">
                        {data.title}
                    </span>
                <span className="counter">
                        {data.amount}
                    </span>
            </div>

        </div>
    );
};

export default Widget;
