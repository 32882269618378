import './modeSpecificStats.scss'

import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import supabase from "../../config/supabaseClient";
import { Paper } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { allHitterColumns, allPitcherColumns } from "../../datatablesource";


const ModeSpecificStats = ({type}) => {

    const [fetchError, setFetchError] = useState("")
    const [hitterData, setHitterData] = useState([]);
    const [pitcherData, setPitcherData] = useState([]);

    const {year} = useParams();
    const {currentUser} = useContext(AuthContext);
    const current_user_email = currentUser.email

    useEffect(() => {

        const fetchData = async () => {

            const {data: hitterData, error: hitterError} = await supabase
                .from('hittersGameStats')
                .select()
                .eq("year", year)
                .eq("user_email", current_user_email)
                .eq("gameModeType", type)

            const {data: pitcherData, error: pitcherError} = await supabase
                .from('pitchersGameStats')
                .select()
                .eq("year", year)
                .eq("user_email", current_user_email)
                .eq("gameModeType", type)

            if (hitterError) {
                console.log("Error fetching hitter data:", hitterError);
                setFetchError(hitterError)
            } else {
                // console.log("Hitter data:", hitterData);

                const hitterStats = {};

                for (let i = 0; i < hitterData.length; i++) {

                    // console.log(hitterData)

                    const {
                        cardType,
                        hitterName,
                        hitterGamesPlayed,
                        hitterAppearances,
                        hitterAtBats,
                        hitterHits,
                        hitterSingles,
                        hitterDoubles,
                        hitterTriples,
                        hitterHomeRuns,
                        hitterRunsBattedIn,
                        hitterWalks,
                        hitterStrikeouts
                    } = hitterData[i];

                    if (!hitterStats[hitterName]) {
                        hitterStats[hitterName] = {
                            cardType: '',
                            hitterGamesPlayed: 0,
                            hitterAppearances: 0,
                            hitterAtBats: 0,
                            hitterHits: 0,
                            hitterSingles: 0,
                            hitterDoubles: 0,
                            hitterTriples: 0,
                            hitterHomeRuns: 0,
                            hitterRunsBattedIn: 0,
                            hitterWalks: 0,
                            hitterStrikeouts: 0,
                        };
                    }

                    hitterStats[hitterName].cardType = cardType;
                    hitterStats[hitterName].hitterGamesPlayed += parseInt(hitterGamesPlayed);
                    hitterStats[hitterName].hitterAppearances += parseInt(hitterAppearances);
                    hitterStats[hitterName].hitterAtBats += parseInt(hitterAtBats);
                    hitterStats[hitterName].hitterHits += parseInt(hitterHits);
                    hitterStats[hitterName].hitterSingles += parseInt(hitterSingles);
                    hitterStats[hitterName].hitterDoubles += parseInt(hitterDoubles);
                    hitterStats[hitterName].hitterTriples += parseInt(hitterTriples);
                    hitterStats[hitterName].hitterHomeRuns += parseInt(hitterHomeRuns);
                    hitterStats[hitterName].hitterRunsBattedIn += parseInt(hitterRunsBattedIn);
                    hitterStats[hitterName].hitterWalks += parseInt(hitterWalks);
                    hitterStats[hitterName].hitterStrikeouts += parseInt(hitterStrikeouts);
                }

                const rows = Object.entries(hitterStats).map(([hitterName, {
                    cardType,
                    hitterGamesPlayed,
                    hitterAppearances,
                    hitterAtBats,
                    hitterHits,
                    hitterSingles,
                    hitterDoubles,
                    hitterTriples,
                    hitterHomeRuns,
                    hitterRunsBattedIn,
                    hitterWalks,
                    hitterStrikeouts,
                }]) => {
                    return {
                        id: hitterName,
                        cardType,
                        hitterName,
                        hitterGamesPlayed,
                        hitterAppearances,
                        hitterAtBats,
                        hitterHits,
                        hitterSingles,
                        hitterDoubles,
                        hitterTriples,
                        hitterHomeRuns,
                        hitterRunsBattedIn,
                        hitterWalks,
                        hitterStrikeouts,
                    };
                });

                // console.log(rows);

                setHitterData(rows)

            } // end of setHitterData


            if (pitcherError) {
                // console.log("Error fetching pitcher data:", pitcherError);
                setFetchError(pitcherError);
            } else {
                // console.log("Pitcher data:", pitcherData);

                const pitcherStats = {};

                for (let i = 0; i < pitcherData.length; i++) {

                    // console.log(data)

                    const {
                        cardType,
                        pitcherName,
                        pitcherGamesPlayed,
                        pitcherInningsPitched,
                        pitcherWins,
                        pitcherLosses,
                        pitcherSaves,
                        pitcherStrikeouts,
                        pitcherWalks,
                        pitcherHits,
                        pitcherEarnedRuns,
                        pitcherHomeruns,
                        pitcherGamesStarted,
                        pitcherCompleteGames,
                        pitcherShutouts,
                        pitcherQualityStarts,
                    } = pitcherData[i];

                    if (!pitcherStats[pitcherName]) {
                        pitcherStats[pitcherName] = {
                            cardType: '',
                            pitcherGamesPlayed: 0,
                            pitcherInningsPitched: 0,
                            pitcherWins: 0,
                            pitcherLosses: 0,
                            pitcherSaves: 0,
                            pitcherStrikeouts: 0,
                            pitcherWalks: 0,
                            pitcherHits: 0,
                            pitcherEarnedRuns: 0,
                            pitcherHomeruns: 0,
                            pitcherGamesStarted: 0,
                            pitcherCompleteGames: 0,
                            pitcherShutouts: 0,
                            pitcherQualityStarts: 0,
                        };
                    }

                    pitcherStats[pitcherName].cardType = cardType;
                    pitcherStats[pitcherName].pitcherGamesPlayed += parseInt(pitcherGamesPlayed);
                    pitcherStats[pitcherName].pitcherInningsPitched += parseInt(pitcherInningsPitched);
                    pitcherStats[pitcherName].pitcherWins += parseInt(pitcherWins);
                    pitcherStats[pitcherName].pitcherLosses += parseInt(pitcherLosses);
                    pitcherStats[pitcherName].pitcherSaves += parseInt(pitcherSaves);
                    pitcherStats[pitcherName].pitcherStrikeouts += parseInt(pitcherStrikeouts);
                    pitcherStats[pitcherName].pitcherWalks += parseInt(pitcherWalks);
                    pitcherStats[pitcherName].pitcherHits += parseInt(pitcherHits);
                    pitcherStats[pitcherName].pitcherEarnedRuns += parseInt(pitcherEarnedRuns);
                    pitcherStats[pitcherName].pitcherHomeruns += parseInt(pitcherHomeruns);
                    pitcherStats[pitcherName].pitcherGamesStarted += parseInt(pitcherGamesStarted);
                    pitcherStats[pitcherName].pitcherCompleteGames += parseInt(pitcherCompleteGames);
                    pitcherStats[pitcherName].pitcherShutouts += parseInt(pitcherShutouts);
                    pitcherStats[pitcherName].pitcherQualityStarts += parseInt(pitcherQualityStarts);

                }

                const rows = Object.entries(pitcherStats).map(([pitcherName, {
                    cardType,
                    pitcherGamesPlayed,
                    pitcherInningsPitched,
                    pitcherWins,
                    pitcherLosses,
                    pitcherSaves,
                    pitcherStrikeouts,
                    pitcherWalks,
                    pitcherHits,
                    pitcherEarnedRuns,
                    pitcherHomeruns,
                    pitcherGamesStarted,
                    pitcherCompleteGames,
                    pitcherShutouts,
                    pitcherQualityStarts
                }]) => {
                    return {
                        id: pitcherName,
                        pitcherName,
                        cardType,
                        pitcherGamesPlayed,
                        pitcherInningsPitched,
                        pitcherWins,
                        pitcherLosses,
                        pitcherSaves,
                        pitcherStrikeouts,
                        pitcherWalks,
                        pitcherHits,
                        pitcherEarnedRuns,
                        pitcherHomeruns,
                        pitcherGamesStarted,
                        pitcherCompleteGames,
                        pitcherShutouts,
                        pitcherQualityStarts
                    };
                });

                // console.log(rows);

                setPitcherData(rows)

            }

        }

        fetchData();

    }, [type, current_user_email])

    const hitterAdvancedStats = [
        {
            field: "hitterAverage",
            headerName: "AVG",
            width: 80,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            renderCell: (params) => {

                const atBats = parseInt(params.row.hitterAtBats)
                const hits = parseInt(params.row.hitterHits)

                const results = (hits / atBats).toFixed(3)

                return (
                    <div className="cellAction">
                        {results}
                    </div>
                );
            },
        },
        {
            field: "onBasePercentage",
            headerName: "OBP",
            width: 80,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            renderCell: (params) => {

                const atBats = parseInt(params.row.hitterAtBats)
                const hits = parseInt(params.row.hitterHits)
                const walks = parseInt(params.row.hitterWalks)

                const results = ((hits + walks) / (atBats + walks)).toFixed(3)

                return (
                    <div className="cellAction">
                        {results}
                    </div>
                );
            },
        },
        {
            field: "slugging",
            headerName: "SLG",
            width: 80,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            renderCell: (params) => {

                const atBats = parseInt(params.row.hitterAtBats)
                const singles = parseInt(params.row.hitterSingles)
                const doubles = parseInt(params.row.hitterDoubles)
                const triples = parseInt(params.row.hitterTriples)
                const homeruns = parseInt(params.row.hitterHomeRuns)

                const results = ((singles + (doubles * 2) + (triples * 3) + (homeruns * 4)) / atBats).toFixed(3)

                return (
                    <div className="cellAction">
                        {results}
                    </div>
                );
            },
        },
        {
            field: "onBasePlusSlugging",
            headerName: "OPS",
            width: 80,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            renderCell: (params) => {

                const atBats = parseInt(params.row.hitterAtBats)
                const hits = parseInt(params.row.hitterHits)
                const singles = parseInt(params.row.hitterSingles)
                const doubles = parseInt(params.row.hitterDoubles)
                const triples = parseInt(params.row.hitterTriples)
                const homeruns = parseInt(params.row.hitterHomeRuns)
                const walks = parseInt(params.row.hitterWalks)

                const obp = ((hits + walks) / (atBats + walks))
                const slugging = ((singles + (doubles * 2) + (triples * 3) + (homeruns * 4)) / atBats)

                const results = (obp + slugging).toFixed(3)

                return (
                    <div className="cellAction">
                        {results}
                    </div>
                );
            },
        },
    ];

    const pitcherAdvancedStats = [
        {
            field: "earnedRunAverage",
            headerName: "ERA",
            width: 80,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            renderCell: (params) => {

                // 9 * (earned runs / innings pitched)

                const earnedRuns = parseInt(params.row.pitcherEarnedRuns)
                const inningsPitched = parseInt(params.row.pitcherInningsPitched)

                const results = (9 * (earnedRuns / inningsPitched)).toFixed(2)

                return (
                    <div className="cellAction">
                        {results}
                    </div>
                );
            },
        },
        {
            field: "fip",
            headerName: "FIP",
            width: 80,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            renderCell: (params) => {

                // fip: (13*hr+3*(bb)-2*SO) / IP + 3.2

                const homeruns = parseInt(params.row.pitcherHomeruns)
                const walks = parseInt(params.row.pitcherWalks)
                const strikeouts = parseInt(params.row.pitcherStrikeouts)
                const inningsPitched = parseInt(params.row.pitcherInningsPitched)

                const results = (((13 * homeruns + (3 * walks) - (2 * strikeouts)) / inningsPitched) + 3.2).toFixed(2)
                const displayResults = results < 0 ? 0 : results;

                return (
                    <div className="cellAction">
                        {displayResults}
                    </div>
                );
            },
        },
        {
            field: "hitsPerNine",
            headerName: "H/9",
            width: 80,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            renderCell: (params) => {

                // 9 * ( hits / innings pitched )

                const hits = parseInt(params.row.pitcherHits)
                const inningsPitched = parseInt(params.row.pitcherInningsPitched)

                const results = (9 * (hits / inningsPitched)).toFixed(2)

                return (
                    <div className="cellAction">
                        {results}
                    </div>
                );
            },
        },
        {
            field: "strikeoutsPerNine",
            headerName: "SO/9",
            width: 80,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            renderCell: (params) => {

                // 9 * (strikeouts / innings pitched)

                const strikeouts = parseInt(params.row.pitcherStrikeouts)
                const inningsPitched = parseInt(params.row.pitcherInningsPitched)

                const results = (9 * (strikeouts / inningsPitched)).toFixed(2)

                return (
                    <div className="cellAction">
                        {results}
                    </div>
                );
            },
        },
        {
            field: "walksPerNine",
            headerName: "BB/9",
            width: 80,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            renderCell: (params) => {

                // 9 * (walks / innings pitched)

                const walks = parseInt(params.row.pitcherWalks)
                const inningsPitched = parseInt(params.row.pitcherInningsPitched)

                const results = (9 * (walks / inningsPitched)).toFixed(2)

                return (
                    <div className="cellAction">
                        {results}
                    </div>
                );
            },
        },
        {
            field: "whip",
            headerName: "WHIP",
            width: 80,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            renderCell: (params) => {

                // (walks + hits) / innings pitched

                const walks = parseInt(params.row.pitcherWalks)
                const hits = parseInt(params.row.pitcherHits)
                const inningsPitched = parseInt(params.row.pitcherInningsPitched)

                const results = ((walks + hits) / inningsPitched).toFixed(2)

                return (
                    <div className="cellAction">
                        {results}
                    </div>
                );
            },
        },
    ]

    return (

        <div className="tableContainer">
            {fetchError && (<p>{fetchError}</p>)}

            {/* one */}
            <h2>Hitter</h2>
            <Paper className="tableWrapper">
                <DataGrid
                    initialState={{
                        sorting: {
                            sortModel: [
                                {field: 'hitterHomeRuns', sort: 'desc'}
                            ],
                        },
                    }}
                    rows={hitterData}
                    columns={allHitterColumns.concat(hitterAdvancedStats)}
                    pageSize={8}
                    rowsPerPageOptions={[8]}
                    checkboxSelection
                />
            </Paper>

            {/* two*/}
            <h2>Pitchers</h2>
            <Paper className="tableWrapper">
                <DataGrid
                    initialState={{
                        sorting: {
                            sortModel: [
                                {field: 'pitcherInningsPitched', sort: 'desc'}
                            ],
                        },
                    }}
                    rows={pitcherData}
                    columns={allPitcherColumns.concat(pitcherAdvancedStats)}
                    pageSize={8}
                    rowsPerPageOptions={[8]}
                    checkboxSelection
                />
            </Paper>
        </div>

    );

}

export default ModeSpecificStats;
