import "./chart.scss";
import {
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    BarChart,
    Legend,
    Bar
} from 'recharts';
import supabase from "../../config/supabaseClient";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { Grid, useMediaQuery } from "@mui/material";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";

const Charts = ({titleLeft, titleRight}) => {

    const {currentUser} = useContext(AuthContext);
    const current_user_email = currentUser.email

    const [fetchError, setFetchError] = useState("")
    const [hitterData, setHitterData] = useState([]);
    const [pitcherData, setPitcherData] = useState([]);

    const {year} = useParams();

    useEffect(() => {

        const fetchData = async () => {

            const {data: hitterData, error: hitterError} = await supabase
                .from('hittersGameStats')
                .select()
                .eq("year", year)
                .eq("user_email", current_user_email);


            const {data: pitcherData, error: pitcherError} = await supabase
                .from('pitchersGameStats')
                .select()
                .eq("year", year)
                .eq("user_email", current_user_email);

            if (hitterError) {
                console.log("Error fetching hitter data:", hitterError);
                setFetchError(hitterError)
            } else {
                // console.log("Hitter data:", hitterData);

                const hitterStats = {};

                let totalAtBats = 0;
                let numberOfHitters = 0;

                // Calculate the total at-bats and number of hitters
                for (let i = 0; i < hitterData.length; i++) {
                    const {
                        cardType,
                        hitterName,
                        hitterAtBats,
                        hitterHits,
                        hitterHomeRuns,
                        hitterRunsBattedIn,
                    } = hitterData[i];

                    if (!hitterStats[hitterName]) {
                        hitterStats[hitterName] = {
                            cardType: '',
                            hitterAtBats: 0,
                            hitterHits: 0,
                            hitterHomeRuns: 0,
                            hitterRunsBattedIn: 0,
                        };
                        numberOfHitters++;
                    }

                    hitterStats[hitterName].cardType = cardType;
                    hitterStats[hitterName].hitterAtBats += parseInt(hitterAtBats);
                    hitterStats[hitterName].hitterHits += parseInt(hitterHits);
                    hitterStats[hitterName].hitterHomeRuns += parseInt(hitterHomeRuns);
                    hitterStats[hitterName].hitterRunsBattedIn += parseInt(hitterRunsBattedIn);

                    totalAtBats += parseInt(hitterAtBats);
                }

                // Calculate the average number of at-bats
                const averageAtBats = totalAtBats / numberOfHitters;

                // Filter the hitters based on the average
                const rows = Object.entries(hitterStats).map(([hitterName, {
                    cardType,
                    hitterAtBats,
                    hitterHits,
                    hitterHomeRuns,
                    hitterRunsBattedIn,
                }]) => {
                    return {
                        id: hitterName,
                        cardType,
                        hitterName,
                        hitterAtBats,
                        hitterHits,
                        hitterHomeRuns,
                        hitterRunsBattedIn,
                    };
                }).filter(hitter => hitter.hitterAtBats > averageAtBats);

                // console.log(rows);

                setHitterData(rows)

            } // end of setHitterData


            if (pitcherError) {
                // console.log("Error fetching pitcher data:", pitcherError);
                setFetchError(pitcherError);
            } else {
                // console.log("Pitcher data:", pitcherData);

                const pitcherStats = {};

                let totalInningsPitched = 0;
                let numberOfPitchers = 0;

                // Calculate the total innings pitched and number of pitchers
                for (let i = 0; i < pitcherData.length; i++) {
                    const {
                        cardType,
                        pitcherName,
                        pitcherGamesPlayed,
                        pitcherInningsPitched,
                        pitcherStrikeouts,
                        pitcherQualityStarts,
                    } = pitcherData[i];

                    if (!pitcherStats[pitcherName]) {
                        pitcherStats[pitcherName] = {
                            cardType: '',
                            pitcherGamesPlayed: 0,
                            pitcherInningsPitched: 0,
                            pitcherStrikeouts: 0,
                            pitcherQualityStarts: 0,
                        };
                        numberOfPitchers++;
                    }

                    pitcherStats[pitcherName].cardType = cardType;
                    pitcherStats[pitcherName].pitcherGamesPlayed += parseInt(pitcherGamesPlayed);
                    pitcherStats[pitcherName].pitcherInningsPitched += parseFloat(pitcherInningsPitched);
                    pitcherStats[pitcherName].pitcherStrikeouts += parseInt(pitcherStrikeouts);
                    pitcherStats[pitcherName].pitcherQualityStarts += parseInt(pitcherQualityStarts);

                    totalInningsPitched += parseFloat(pitcherInningsPitched);
                }

                const averageInningsPitched = totalInningsPitched / numberOfPitchers;

                const rows = Object.entries(pitcherStats).map(([pitcherName, {
                    cardType,
                    pitcherGamesPlayed,
                    pitcherInningsPitched,
                    pitcherStrikeouts,
                    pitcherQualityStarts
                }]) => {
                    return {
                        id: pitcherName,
                        pitcherName,
                        cardType,
                        pitcherGamesPlayed,
                        pitcherInningsPitched,
                        pitcherStrikeouts,
                        pitcherQualityStarts
                    };
                }).filter(pitcher => pitcher.pitcherInningsPitched > averageInningsPitched); // Filter out pitchers with innings pitched less than or equal to the average


                // console.log(rows);

                setPitcherData(rows)

            }

        }

        fetchData();

    }, [year, current_user_email])

    //console.log(hitterData)

    // calculate the maximum value of all data points
    // Hitters
    const maxAB = Math.max(
        ...hitterData.map((data) => data.hitterAtBats)
    );

    const maxHits = Math.max(
        ...hitterData.map((data) => data.hitterHits)
    );

    const maxHomeRuns = Math.max(
        ...hitterData.map((data) => data.hitterHomeRuns)
    );

    const maxRunsBattedIn = Math.max(
        ...hitterData.map((data) => data.hitterRunsBattedIn)
    );

    // Pitchers
    const maxGP = Math.max(
        ...pitcherData.map((data) => data.pitcherGamesPlayed)
    );

    const maxIP = Math.max(
        ...pitcherData.map((data) => data.pitcherInningsPitched)
    );

    const maxSO = Math.max(
        ...pitcherData.map((data) => data.pitcherStrikeouts)
    );

    const maxQS = Math.max(
        ...pitcherData.map((data) => data.pitcherQualityStarts)
    );

    const isMobileMax = useMediaQuery(
        '(max-width:600px)'
    );

    const isMobileMin = useMediaQuery(
        '(max-width:375px)'
    );

    // const renderTooltipContent = (value, name, props) => {
    //     const { payload } = props;
    //     // You can format the value here if needed, or add additional data
    //     return [`${name}: ${value}`, `${payload.cardType}`];
    // };

    return (
        <div className="chart">
            <div className="content">
                {fetchError &&
                    <Grid
                        container
                        className="errorMessage"
                    >
                        <Grid item>
                            Something went wrong.
                        </Grid>
                        <Grid item>
                            <SentimentVeryDissatisfiedIcon fontSize="large" className="icon"/>
                        </Grid>
                    </Grid>
                }

                <div className="chartContainer">


                    <div
                        className={isMobileMin ? 'hidden' : 'chartWrapper'}
                        style={{
                            marginBottom: 200,
                        }}
                    >

                        <h2>{titleLeft}</h2>
                        <ResponsiveContainer
                            aspect={2}
                            width={isMobileMax ? '100%' : '65%'}
                            height={isMobileMax ? '100%' :'60%'}
                        >
                            <BarChart
                                data={hitterData}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3"/>
                                <XAxis dataKey="hitterName" className="xAxisText"/>
                                <YAxis
                                    type="number"
                                    domain={[0, Math.max(maxAB, maxHits, maxHomeRuns, maxRunsBattedIn) + 20]}
                                    tick={{fill: '#BA94D1'}}
                                />
                                <Tooltip/>
                                <Legend/>
                                <Bar name="AB" dataKey="hitterAtBats" fill="#037d50"/>
                                <Bar name="Card Type" dataKey="cardTypeHitter" fill="#037d80"/>
                                <Bar name="Hits" dataKey="hitterHits" fill="#038cfc"/>
                                <Bar name="HRs" dataKey="hitterHomeRuns" fill="#fc0349"/>
                                <Bar name="RBI" dataKey="hitterRunsBattedIn" fill="#cbd300"/>
                            </BarChart>
                        </ResponsiveContainer>

                    </div>

                    <div className={isMobileMin ? 'hidden' : 'chartWrapper'}>
                        <h2>{titleRight}</h2>
                        <ResponsiveContainer
                            aspect={2}
                            width={isMobileMax ? '100%' : '65%'}
                            height={isMobileMax ? '100%' :'60%'}
                        >
                            <BarChart
                                data={pitcherData}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3"/>
                                <XAxis dataKey="pitcherName"/>
                                <YAxis
                                    type="number"
                                    domain={[0, Math.max(maxGP, maxIP, maxSO, maxQS) + 20]}
                                    tick={{fill: '#BA94D1'}}
                                />
                                <Tooltip/>
                                <Legend/>
                                <Bar className="barClass" name="GP" dataKey="pitcherGamesPlayed" fill="#FF0000"/>
                                <Bar className="barClass" name="IP" dataKey="pitcherInningsPitched" fill="#8B8000"/>
                                <Bar className="barClass" name="SO" dataKey="pitcherStrikeouts" fill="#be00ef"/>
                                <Bar className="barClass" name="QS" dataKey="pitcherQualityStarts" fill="#3a9729"/>
                            </BarChart>
                        </ResponsiveContainer>

                    </div>

                </div>

            </div>

        </div>
    );

}

export default Charts;
