import './patchNotes.scss'
import Sidebar from "../../components/sidebar/sidebar";
import Footer from "../../components/footer/footer";
import { useState } from "react";

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const PatchNotes = () => {

    const [expanded, setExpanded] = useState([]);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? [...expanded, panel] : expanded.filter((p) => p !== panel));
    };

    return (
        <div className="patchNotesContainer">

            <Sidebar/>

            <div className="content">

                <h1 className="title">Patch Notes</h1>

                <div className="accordionContainer">


                    <Accordion
                        expanded={expanded.includes("panel7")}
                        onChange={handleChange("panel7")}
                        className="accordion"
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel7bh-content"
                            id="panel7bh-header"
                            sx={{textAlign: "center"}}
                        >
                            <Typography className="articleHeader">
                                V 1.4.3 - March 31st Release
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                            style={{
                                maxHeight: expanded.includes("panel7") ? "1000px" : "0",
                                overflow: "hidden",
                                transition: "max-height 0.2s ease-out",
                            }}
                        >
                            <div className="articleContent">

                                <h3>Bug Fixes</h3>
                                <br/>
                                <span>Issue 50</span>
                                <ul>
                                    <li>Fix css issues on mobile, some may be remaining</li>
                                </ul>

                                <br/>
                                <span>Issue 59</span>
                                <ul>
                                    <li>Change background color when adding new game player stats on dark mode,
                                        original background color made it hard to read the text</li>
                                </ul>

                                <br/>
                                <span>Issue 61</span>
                                <ul>
                                    <li>Removed game number column from player game stats, it'll stay in game stat table</li>
                                </ul>

                                <br/>
                                <span>Issue 63/66</span>
                                <ul>
                                    <li>Removed the MLBTS 22 Sidebar, contact me if you want this added for you on Discord</li>
                                </ul>

                                <br/>
                                <span>Issue 67</span>
                                <ul>
                                    <li>Set the sidebar to start closed on mobile, used to always start open</li>
                                </ul>

                                <br/>
                                <span>Issue 68</span>
                                <ul>
                                    <li>Fixed the dashboard start chart from being super small on mobile</li>
                                </ul>

                                <br/>
                                <span>Issue 70</span>
                                <ul>
                                    <li>Removed the MLBTS 22 picture and link on the welcome page after logging in, contact me if you want this added for you on Discord</li>
                                </ul>

                                <br/>
                                <span>Issue 71</span>
                                <ul>
                                    <li>Made the new player page look better on mobile</li>
                                </ul>

                            </div>

                        </AccordionDetails>
                    </Accordion>


                    <Accordion
                        expanded={expanded.includes("panel6")}
                        onChange={handleChange("panel6")}
                        className="accordion"
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel6bh-content"
                            id="panel6bh-header"
                            sx={{textAlign: "center"}}
                        >
                            <Typography className="articleHeader">
                                V 1.4.2 - March 30th Release
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                            style={{
                                maxHeight: expanded.includes("panel6") ? "1000px" : "0",
                                overflow: "hidden",
                                transition: "max-height 0.2s ease-out",
                            }}
                        >
                            <div className="articleContent">

                                <h3>Added</h3>

                                <span>
                                     Ability to edit player stats on the view single player screen
                                </span>

                                <h3>Fixed</h3>
                                <ul>
                                    <li>Minor bugs</li>
                                </ul>

                            </div>

                        </AccordionDetails>
                    </Accordion>


                    <Accordion
                        expanded={expanded.includes("panel5")}
                        onChange={handleChange("panel5")}
                        className="accordion"
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel5bh-content"
                            id="panel5bh-header"
                            sx={{textAlign: "center"}}
                        >
                            <Typography className="articleHeader">
                                V 1.4 - March 29th Release
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                            style={{
                                maxHeight: expanded.includes("panel5") ? "1000px" : "0",
                                overflow: "hidden",
                                transition: "max-height 0.2s ease-out",
                            }}
                        >
                            <div className="articleContent">

                                <h3>Added</h3>

                                <span>
                                     Ability to add bulk player game stats
                                </span>

                                <span>
                                    Now you can add all 9 or more players that played in that game to your stat log at once.
                                    <ol>
                                        <li>Click on All *Position*</li>
                                        <li>Click "ADD NEW HITTER GAME STATS"</li>
                                        <li>Add player game stats</li>
                                    </ol>
                                    Currently, there is a limit to the number of players that can be added at once.
                                </span>
                                <br/>
                                <span>
                                    Every dashboard and chart was updated to reflect new player data.
                                </span>


                                <h3>Fixed</h3>
                                <ul>
                                    <li>Minor bugs</li>
                                    <li>QOL issue when adding stats, you would not know if it was successful, now it does.</li>
                                </ul>

                            </div>

                        </AccordionDetails>
                    </Accordion>

                    <Accordion
                        expanded={expanded.includes("panel4")}
                        onChange={handleChange("panel4")}
                        className="accordion"
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                            sx={{textAlign: "center"}}
                        >
                            <Typography className="articleHeader">
                                V 1.3.2 - March 26th Release
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                            style={{
                                maxHeight: expanded.includes("panel4") ? "1000px" : "0",
                                overflow: "hidden",
                                transition: "max-height 0.2s ease-out",
                            }}
                        >
                            <div className="articleContent">
                                <h3>Added</h3>

                                <span>
                                     New card types that are in DD
                                </span>
                            </div>

                        </AccordionDetails>
                    </Accordion>

                    <Accordion
                        expanded={expanded.includes("panel3")}
                        onChange={handleChange("panel3")}
                        className="accordion"
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel3bh-content"
                            id="panel3bh-header"
                            sx={{textAlign: "center"}}
                        >
                            <Typography className="articleHeader">
                                V 1.3.0 - March 23rd Release
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                            style={{
                                maxHeight: expanded.includes("panel3") ? "1000px" : "0",
                                overflow: "hidden",
                                transition: "max-height 0.2s ease-out",
                            }}
                        >
                            <div className="articleContent">
                                <h3>Added</h3>

                                <span>
                                     Code to handle the test user
                                </span>
                            </div>

                        </AccordionDetails>
                    </Accordion>


                    <Accordion
                        expanded={expanded.includes("panel2")}
                        onChange={handleChange("panel2")}
                        className="accordion"
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel2bh-content"
                            id="panel2bh-header"
                            sx={{textAlign: "center"}}
                        >
                            <Typography className="articleHeader">
                                V 1.2 - March 22nd Release
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                            style={{
                                maxHeight: expanded.includes("panel2") ? "1000px" : "0",
                                overflow: "hidden",
                                transition: "max-height 0.2s ease-out",
                            }}
                        >
                            <div className="articleContent">

                                <h3>Added</h3>

                                <span>
                                     Added this patch notes page
                                </span>

                                <span>
                                    Added ability for users to add in card type and player position, now they can see
                                    pre-selected options like:
                                </span>
                                <br/><br/>
                                <span>
                                     Positions
                                </span>
                                <ul>
                                    <li>C</li>
                                    <li>DH</li>
                                    <li>3B</li>
                                    <li>SP</li>
                                    <li>CP</li>
                                </ul>
                                <br/>
                                <span>
                                    Card Types
                                </span>
                                <ul>
                                    <li>Postseason</li>
                                    <li>Finest</li>
                                    <li>Live</li>
                                    <li>Signature</li>
                                </ul>

                                <h3>Fixed</h3>
                                <ul>
                                    <li>Login Page not displaying</li>
                                    <li>Background color on add team stats page</li>
                                    <li>Color scheme on dashboard player graphs</li>
                                    <li>Made dark mode start on initial page load</li>
                                    <li>Centered light and dark mode buttons on sidebar</li>
                                </ul>

                            </div>

                        </AccordionDetails>
                    </Accordion>

                    <Accordion
                        expanded={expanded.includes("panel1")}
                        onChange={handleChange("panel1")}
                        className="accordion"
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            sx={{textAlign: "center"}}
                        >
                            <Typography className="articleHeader">
                                V 1.1.2 - March 20th Release
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                            style={{
                                maxHeight: expanded.includes("panel1") ? "1000px" : "0",
                                overflow: "hidden",
                                transition: "max-height 0.2s ease-out",
                            }}
                        >
                            <div className="articleContent">

                                <h3>Added</h3>

                                Added in ability for users to view individual player stats that pertain to specific
                                game modes. Users can now see stats in:
                                <ul>
                                    <li>Solo Ranked</li>
                                    <li>Co-Op Ranked</li>
                                    <li>Custom League</li>
                                    <li>Battle Royale</li>
                                </ul>
                            </div>

                        </AccordionDetails>
                    </Accordion>

                </div>

                <Footer/>

            </div>

        </div>
    )

}

export default PatchNotes
