import "./allPlayers.scss";

import {
    allHitterColumns, allPitcherColumns,
} from "../../datatablesource";
import { DataGrid } from "@mui/x-data-grid";
import { Link, useParams } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import supabase from "../../config/supabaseClient";
import Sidebar from "../../components/sidebar/sidebar";
import { AuthContext } from "../../context/AuthContext";
import Footer from "../../components/footer/footer";

const AllPlayers = ({type, title}) => {

    const [fetchError, setFetchError] = useState("")
    const [hitterData, setHitterData] = useState([]);
    const [pitcherData, setPitcherData] = useState([]);
    const [sortModel, setSortModel] = useState([
        { field: 'hitterAverage', sort: 'desc' },
    ]);

    const {year} = useParams();
    const {currentUser} = useContext(AuthContext);
    const current_user_email = currentUser.email

    useEffect(() => {

        const fetchData = async () => {

            const {data: hitterData, error: hitterError} = await supabase
                .from('hittersGameStats')
                .select()
                .eq("year", year)
                .eq("user_email", current_user_email);


            const {data: pitcherData, error: pitcherError} = await supabase
                .from('pitchersGameStats')
                .select()
                .eq("year", year)
                .eq("user_email", current_user_email);

            if (hitterError) {
                console.log("Error fetching hitter data:", hitterError);
                setFetchError(hitterError)
            } else {
                // console.log("Hitter data:", hitterData);

                const hitterStats = {};

                for (let i = 0; i < hitterData.length; i++) {
                    const {
                        cardType,
                        hitterName,
                        hitterGamesPlayed,
                        hitterAppearances,
                        hitterAtBats,
                        hitterHits,
                        hitterSingles,
                        hitterDoubles,
                        hitterTriples,
                        hitterHomeRuns,
                        hitterRunsBattedIn,
                        hitterWalks,
                        hitterStrikeouts
                    } = hitterData[i];

                    // Create a unique key for each player and team combination
                    const key = `${hitterName}-${cardType}`;

                    if (!hitterStats[key]) {
                        hitterStats[key] = {
                            cardType,
                            hitterName,
                            hitterGamesPlayed: 0,
                            hitterAppearances: 0,
                            hitterAtBats: 0,
                            hitterHits: 0,
                            hitterSingles: 0,
                            hitterDoubles: 0,
                            hitterTriples: 0,
                            hitterHomeRuns: 0,
                            hitterRunsBattedIn: 0,
                            hitterWalks: 0,
                            hitterStrikeouts: 0,
                        };
                    }

                    // Now, sum the stats for each unique player and team combination
                    hitterStats[key].hitterGamesPlayed += parseInt(hitterGamesPlayed);
                    hitterStats[key].hitterAppearances += parseInt(hitterAppearances);
                    hitterStats[key].hitterAtBats += parseInt(hitterAtBats);
                    hitterStats[key].hitterHits += parseInt(hitterHits);
                    hitterStats[key].hitterSingles += parseInt(hitterSingles);
                    hitterStats[key].hitterDoubles += parseInt(hitterDoubles);
                    hitterStats[key].hitterTriples += parseInt(hitterTriples);
                    hitterStats[key].hitterHomeRuns += parseInt(hitterHomeRuns);
                    hitterStats[key].hitterRunsBattedIn += parseInt(hitterRunsBattedIn);
                    hitterStats[key].hitterWalks += parseInt(hitterWalks);
                    hitterStats[key].hitterStrikeouts += parseInt(hitterStrikeouts);
                }

                const rows = Object.entries(hitterStats).map(([key, {
                    cardType,
                    hitterName,
                    hitterGamesPlayed,
                    hitterAppearances,
                    hitterAtBats,
                    hitterHits,
                    hitterSingles,
                    hitterDoubles,
                    hitterTriples,
                    hitterHomeRuns,
                    hitterRunsBattedIn,
                    hitterWalks,
                    hitterStrikeouts,
                }]) => {
                    return {
                        id: key,
                        cardType,
                        hitterName,
                        hitterGamesPlayed,
                        hitterAppearances,
                        hitterAtBats,
                        hitterHits,
                        hitterSingles,
                        hitterDoubles,
                        hitterTriples,
                        hitterHomeRuns,
                        hitterRunsBattedIn,
                        hitterWalks,
                        hitterStrikeouts,
                    };
                });

                // console.log(rows, JSON.stringify(rows, null, 2));

                setHitterData(rows)

            } // end of setHitterData


            if (pitcherError) {
                // console.log("Error fetching pitcher data:", pitcherError);
                setFetchError(pitcherError);
            } else {
                // console.log("Pitcher data:", pitcherData);

                const pitcherStats = {};

                for (let i = 0; i < pitcherData.length; i++) {

                    // console.log(data)

                    const {
                        cardType,
                        pitcherName,
                        pitcherGamesPlayed,
                        pitcherInningsPitched,
                        pitcherWins,
                        pitcherLosses,
                        pitcherSaves,
                        pitcherStrikeouts,
                        pitcherWalks,
                        pitcherHits,
                        pitcherEarnedRuns,
                        pitcherHomeruns,
                        pitcherGamesStarted,
                        pitcherCompleteGames,
                        pitcherShutouts,
                        pitcherQualityStarts,
                    } = pitcherData[i];

                    // Create a unique key for each player and team combination
                    const key = `${pitcherName}-${cardType}`;


                    if (!pitcherStats[key]) {
                        pitcherStats[key] = {
                            cardType,
                            pitcherName,
                            pitcherGamesPlayed: 0,
                            pitcherInningsPitched: 0,
                            pitcherWins: 0,
                            pitcherLosses: 0,
                            pitcherSaves: 0,
                            pitcherStrikeouts: 0,
                            pitcherWalks: 0,
                            pitcherHits: 0,
                            pitcherEarnedRuns: 0,
                            pitcherHomeruns: 0,
                            pitcherGamesStarted: 0,
                            pitcherCompleteGames: 0,
                            pitcherShutouts: 0,
                            pitcherQualityStarts: 0,
                        };
                    }

                    //  hitterStats[key].hitterGamesPlayed += parseInt(hitterGamesPlayed)
                    pitcherStats[key].pitcherGamesPlayed += parseInt(pitcherGamesPlayed);
                    pitcherStats[key].pitcherInningsPitched += parseInt(pitcherInningsPitched);
                    pitcherStats[key].pitcherWins += parseInt(pitcherWins);
                    pitcherStats[key].pitcherLosses += parseInt(pitcherLosses);
                    pitcherStats[key].pitcherSaves += parseInt(pitcherSaves);
                    pitcherStats[key].pitcherStrikeouts += parseInt(pitcherStrikeouts);
                    pitcherStats[key].pitcherWalks += parseInt(pitcherWalks);
                    pitcherStats[key].pitcherHits += parseInt(pitcherHits);
                    pitcherStats[key].pitcherEarnedRuns += parseInt(pitcherEarnedRuns);
                    pitcherStats[key].pitcherHomeruns += parseInt(pitcherHomeruns);
                    pitcherStats[key].pitcherGamesStarted += parseInt(pitcherGamesStarted);
                    pitcherStats[key].pitcherCompleteGames += parseInt(pitcherCompleteGames);
                    pitcherStats[key].pitcherShutouts += parseInt(pitcherShutouts);
                    pitcherStats[key].pitcherQualityStarts += parseInt(pitcherQualityStarts);

                }

                const rows = Object.entries(pitcherStats).map(([key, {
                    pitcherName,
                    cardType,
                    pitcherGamesPlayed,
                    pitcherInningsPitched,
                    pitcherWins,
                    pitcherLosses,
                    pitcherSaves,
                    pitcherStrikeouts,
                    pitcherWalks,
                    pitcherHits,
                    pitcherEarnedRuns,
                    pitcherHomeruns,
                    pitcherGamesStarted,
                    pitcherCompleteGames,
                    pitcherShutouts,
                    pitcherQualityStarts
                }]) => {
                    return {
                        id: key,
                        pitcherName,
                        cardType,
                        pitcherGamesPlayed,
                        pitcherInningsPitched,
                        pitcherWins,
                        pitcherLosses,
                        pitcherSaves,
                        pitcherStrikeouts,
                        pitcherWalks,
                        pitcherHits,
                        pitcherEarnedRuns,
                        pitcherHomeruns,
                        pitcherGamesStarted,
                        pitcherCompleteGames,
                        pitcherShutouts,
                        pitcherQualityStarts
                    };
                });

                // console.log(rows);

                setPitcherData(rows)

            }

        }

        fetchData();

        setSortModel([
            { field: 'hitterHomeRuns', sort: 'desc' }
        ]);

    }, [year, current_user_email])

    const hitterAdvancedStats = [
        // {
        //     field: "hitterAverage",
        //     headerName: "AVG",
        //     width: 80,
        //     headerAlign: 'center',
        //     align: 'center',
        //     type: "number",
        //     sortComparator: (v1, v2, row1, row2) => {
        //         const a1 = parseFloat(row1.hitterHits) / parseFloat(row1.hitterAtBats);
        //         const a2 = parseFloat(row2.hitterHits) / parseFloat(row2.hitterAtBats);
        //         return a1 - a2;
        //     },
        //     renderCell: (params) => {
        //         const atBats = parseFloat(params.row.hitterAtBats);
        //         const hits = parseFloat(params.row.hitterHits);
        //         const average = atBats > 0 ? hits / atBats : 0;
        //         return (
        //             <div className="cellAction">
        //                 {average.toFixed(3)}
        //             </div>
        //         );
        //     },
        // },

        // {
        //     field: "onBasePercentage",
        //     headerName: "OBP",
        //     width: 80,
        //     headerAlign: 'center',
        //     align: 'center',
        //     type: "number",
        //     renderCell: (params) => {
        //
        //         const atBats = parseInt(params.row.hitterAtBats)
        //         const hits = parseInt(params.row.hitterHits)
        //         const walks = parseInt(params.row.hitterWalks)
        //
        //         const results = ((hits + walks) / (atBats + walks)).toFixed(3)
        //
        //         return (
        //             <div className="cellAction">
        //                 {results}
        //             </div>
        //         );
        //     },
        // },
        // {
        //     field: "slugging",
        //     headerName: "SLG",
        //     width: 80,
        //     headerAlign: 'center',
        //     align: 'center',
        //     type: "number",
        //     renderCell: (params) => {
        //
        //         const atBats = parseInt(params.row.hitterAtBats)
        //         const singles = parseInt(params.row.hitterSingles)
        //         const doubles = parseInt(params.row.hitterDoubles)
        //         const triples = parseInt(params.row.hitterTriples)
        //         const homeruns = parseInt(params.row.hitterHomeRuns)
        //
        //         const results = ((singles + (doubles * 2) + (triples * 3) + (homeruns * 4)) / atBats).toFixed(3)
        //
        //         return (
        //             <div className="cellAction">
        //                 {results}
        //             </div>
        //         );
        //     },
        // },
        // {
        //     field: "onBasePlusSlugging",
        //     headerName: "OPS",
        //     width: 80,
        //     headerAlign: 'center',
        //     align: 'center',
        //     type: "number",
        //     renderCell: (params) => {
        //
        //         const atBats = parseInt(params.row.hitterAtBats)
        //         const hits = parseInt(params.row.hitterHits)
        //         const singles = parseInt(params.row.hitterSingles)
        //         const doubles = parseInt(params.row.hitterDoubles)
        //         const triples = parseInt(params.row.hitterTriples)
        //         const homeruns = parseInt(params.row.hitterHomeRuns)
        //         const walks = parseInt(params.row.hitterWalks)
        //
        //         const obp = ((hits + walks) / (atBats + walks))
        //         const slugging = ((singles + (doubles * 2) + (triples * 3) + (homeruns * 4)) / atBats)
        //
        //         const results = (obp + slugging).toFixed(3)
        //
        //         return (
        //             <div className="cellAction">
        //                 {results}
        //             </div>
        //         );
        //     },
        // },
        {
            field: "hitterAction",
            headerName: "View Player Stats",
            width: 200,
            headerAlign: 'center',
            align: 'center',
            type: "number",
            renderCell: (params) => {

                return (
                    <div className="cellAction">
                        <Link
                            to={`/${year}/player/${params.row.hitterName}/${params.row.cardType}/Hitter`}
                            style={{textDecoration: "none"}}>
                            <div className="viewButton">View</div>
                        </Link>
                    </div>
                );
            },
        },
    ];

    const pitcherAdvancedStats = [
        {
            field: "earnedRunAverage",
            headerName: "ERA",
            width: 80,
            headerAlign: 'center',
            align: 'center',
            type: "number",
            renderCell: (params) => {

                // 9 * (earned runs / innings pitched)

                const earnedRuns = parseInt(params.row.pitcherEarnedRuns)
                const inningsPitched = parseInt(params.row.pitcherInningsPitched)

                const results = (9 * (earnedRuns / inningsPitched)).toFixed(2)

                return (
                    <div className="cellAction">
                        {results}
                    </div>
                );
            },
        },
        {
            field: "fip",
            headerName: "FIP",
            width: 80,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {

                // fip: (13*hr+3*(bb)-2*SO) / IP + 3.2

                const homeruns = parseInt(params.row.pitcherHomeruns)
                const walks = parseInt(params.row.pitcherWalks)
                const strikeouts = parseInt(params.row.pitcherStrikeouts)
                const inningsPitched = parseInt(params.row.pitcherInningsPitched)

                const results = (((13 * homeruns + (3 * walks) - (2 * strikeouts)) / inningsPitched) + 3.2).toFixed(2)
                const displayResults = results < 0 ? 0 : results;

                return (
                    <div className="cellAction">
                        {displayResults}
                    </div>
                );
            },
        },
        {
            field: "hitsPerNine",
            headerName: "H/9",
            width: 80,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {

                // 9 * ( hits / innings pitched )

                const hits = parseInt(params.row.pitcherHits)
                const inningsPitched = parseInt(params.row.pitcherInningsPitched)

                const results = (9 * (hits / inningsPitched)).toFixed(2)

                return (
                    <div className="cellAction">
                        {results}
                    </div>
                );
            },
        },
        {
            field: "strikeoutsPerNine",
            headerName: "SO/9",
            width: 80,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {

                // 9 * (strikeouts / innings pitched)

                const strikeouts = parseInt(params.row.pitcherStrikeouts)
                const inningsPitched = parseInt(params.row.pitcherInningsPitched)

                const results = (9 * (strikeouts / inningsPitched)).toFixed(2)

                return (
                    <div className="cellAction">
                        {results}
                    </div>
                );
            },
        },
        {
            field: "walksPerNine",
            headerName: "BB/9",
            width: 80,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {

                // 9 * (walks / innings pitched)

                const walks = parseInt(params.row.pitcherWalks)
                const inningsPitched = parseInt(params.row.pitcherInningsPitched)

                const results = (9 * (walks / inningsPitched)).toFixed(2)

                return (
                    <div className="cellAction">
                        {results}
                    </div>
                );
            },
        },
        {
            field: "whip",
            headerName: "WHIP",
            width: 80,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {

                // (walks + hits) / innings pitched

                const walks = parseInt(params.row.pitcherWalks)
                const hits = parseInt(params.row.pitcherHits)
                const inningsPitched = parseInt(params.row.pitcherInningsPitched)

                const results = ((walks + hits) / inningsPitched).toFixed(2)

                return (
                    <div className="cellAction">
                        {results}
                    </div>
                );
            },
        },
        {
            field: "pitcherAction",
            headerName: "View Player Stats",
            width: 200,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <Link
                            to={`/${year}/player/${params.row.pitcherName}/${params.row.cardType}/Pitcher`}
                            style={{textDecoration: "none"}}>
                            <div className="viewButton">View</div>
                        </Link>
                    </div>
                );
            },
        },
    ]

    //console.log(hitterData, JSON.stringify(hitterData, null, 2));

    return (

        <div className="single">
            <Sidebar/>
            <div className="allPlayersContainer">
                <div className="content">
                    {fetchError && (<p>{fetchError}</p>)}
                    <h1 className="title">{title}</h1>
                    <p className="context">If you just added a player and don't see them below, you need to add their stats and then they will appear.</p>
                    {
                        current_user_email === 'baseballstatstrackertestuser@gmail.com'
                            ? (
                                <></>
                            ) : (
                                <>
                                    {
                                        type === "Hitter"
                                            ? (
                                                <div className="linkContainer">
                                                    <Link to={`/${year}/hitter/new`} className="link">
                                                        Add New Hitter
                                                    </Link>

                                                    <Link to={`/${year}/new/playerStats/hitter`} className="link secondLink">
                                                        Add New Hitter Game Stats (Single or Bulk Stats)
                                                    </Link>
                                                </div>
                                            ) : (
                                                <div className="linkContainer">
                                                    <Link to={`/${year}/pitcher/new`} className="link">
                                                        Add New Pitcher
                                                    </Link>

                                                    <Link to={`/${year}/new/playerStats/pitcher`} className="link secondLink">
                                                        Add New Pitcher Game Stats (Single or Bulk Stats)
                                                    </Link>
                                                </div>
                                            )
                                    }
                                </>
                            )
                    }
                    <br/>
                    <div className="dataGridTable">

                        {type === "Hitter"

                            ? <DataGrid
                                initialState={{
                                    sorting: {
                                        sortModel: [
                                            { field: 'hitterHomeRuns', sort: 'desc' },
                                        ],
                                    },
                                }}
                                className="datagrid"
                                rows={hitterData}
                                columns={allHitterColumns.concat(hitterAdvancedStats)}
                                pageSize={15}
                                rowsPerPageOptions={[15]}
                                checkboxSelection
                            />

                            : <DataGrid
                                initialState={{
                                    sorting: {
                                        sortModel: [
                                            {field: 'pitcherInningsPitched', sort: 'desc'}
                                        ],
                                    },
                                }}
                                rows={pitcherData}
                                columns={allPitcherColumns.concat(pitcherAdvancedStats)}
                                pageSize={15}
                                rowsPerPageOptions={[15]}
                                checkboxSelection
                            />

                        }

                    </div>
                </div>
                <Footer/>
            </div>
        </div>


    );
};

export default AllPlayers;
