import './BulkAddStats.scss'
import Sidebar from "../../../components/sidebar/sidebar";
import { useContext, useEffect, useState } from "react";
import supabase from "../../../config/supabaseClient";
import { addBulkDataHitterGameStatsInput, addBulkDataPitcherGameStatsInput } from "../../../formSource";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import Swal from "sweetalert2";
import {
    Autocomplete,
    Button,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TextField
} from "@mui/material";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const BulkAddStats = () => {

    const [players, setPlayers] = useState([]);
    const [cardType, setCardType] = useState([]);

    const {year} = useParams();
    const {type} = useParams();
    const {currentUser} = useContext(AuthContext);
    const current_user_email = currentUser.email
    const current_user_id = currentUser.id

    const tableType = type.toLowerCase() + "s";
    // console.log(tableType)
    const columnType = type.toLowerCase() + "Name";
    // console.log(columnType)

    const insertTableName = type + "sGameStats"

    // console.log(insertTableName)


    const initialRowState = type === 'hitter' ?
        {
            hitterName: "",
            cardType: "",
            hitterGamesPlayed: "",
            hitterAppearances: "",
            hitterAtBats: "",
            hitterHits: "",
            hitterSingles: "",
            hitterDoubles: "",
            hitterTriples: "",
            hitterHomeRuns: "",
            hitterRunsBattedIn: "",
            hitterWalks: "",
            hitterStrikeouts: "",
            gameModeType: "",
        }
        :
        {
            pitcherName: "",
            cardType: "",
            pitcherGamesPlayed: "",
            pitcherInningsPitched: "",
            pitcherWins: "",
            pitcherLosses: "",
            pitcherSaves: "",
            pitcherStrikeouts: "",
            pitcherWalks: "",
            pitcherHits: "",
            pitcherEarnedRuns: "",
            pitcherHomeruns: "",
            pitcherGamesStarted: "",
            pitcherCompleteGames: "",
            pitcherShutouts: "",
            pitcherQualityStarts: "",
            gameModeType: "",
        };


    const [rows, setRows] = useState([initialRowState]);

    useEffect(() => {
        const fetchPlayers = async () => {

            const {data, error} = await supabase
                .from(tableType)
                .select(columnType + ", cardType")
                .eq("user_email", current_user_email)
                .eq("year", year);

            if (error) {
                console.error("Error fetching players:", error);
                await Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Error fetching players',
                });
            } else {

                // // Create a map to store unique player-cardType pairs
                // const uniquePlayerCardMap = new Map();
                //
                // // Loop through each row in the data
                // for (const row of data) {
                //     const playerName = row[columnType];
                //     const cardType = row.cardType;
                //
                //     // Use the combination of playerName and cardType as a unique key
                //     const key = playerName + "-" + cardType;
                //     if (!uniquePlayerCardMap.has(key)) {
                //         uniquePlayerCardMap.set(key, { [columnType]: playerName, cardType: cardType });
                //     }
                // }
                //
                // // Convert the map values to an array and add a numeric key to each object
                // let counter = 0;
                // const players = Array.from(uniquePlayerCardMap.values()).map(player => {
                //     return { number: counter++, ...player };
                // });
                //
                // console.log(players)



                //console.log("data:", data); // add this
                const uniquePlayers = new Set(data.map((row) => row[columnType]));
                //console.log("uniquePlayers:", uniquePlayers); // add this
                const players = [...uniquePlayers].map((player) => ({[columnType]: player}));
                //console.log("players:", players); // add this
                setPlayers(players);
            }

        };

        fetchPlayers();
    }, [year, current_user_email]);

    // console.log(players)

    const addHitterRow = () => {
        setRows((prevState) => [
            ...prevState,
            {
                hitterName: "",
                cardType: "",
                hitterGamesPlayed: "",
                hitterAppearances: "",
                hitterAtBats: "",
                hitterHits: "",
                hitterSingles: "",
                hitterDoubles: "",
                hitterTriples: "",
                hitterHomeRuns: "",
                hitterRunsBattedIn: "",
                hitterWalks: "",
                hitterStrikeouts: "",
                gameModeType: "",
                user_email: current_user_email,
                year: year
            },
        ]);
    };

    const addPitcherRow = () => {
        setRows((prevState) => [
            ...prevState,
            {
                pitcherName: "",
                cardType: "",
                pitcherGamesPlayed: "",
                pitcherInningsPitched: "",
                pitcherWins: "",
                pitcherLosses: "",
                pitcherSaves: "",
                pitcherStrikeouts: "",
                pitcherWalks: "",
                pitcherHits: "",
                pitcherEarnedRuns: "",
                pitcherHomeruns: "",
                pitcherGamesStarted: "",
                pitcherCompleteGames: "",
                pitcherShutouts: "",
                pitcherQualityStarts: "",
                gameModeType: "",
                user_email: current_user_email,
                year: year,
            },
        ]);
    };

    const updateCardType = async (index, playerName) => {
        const {data, error} = await supabase
            .from(tableType)
            .select("cardType")
            .eq(columnType, playerName)
            .eq("user_email", current_user_email)
            .eq("year", year);

        if (error) {
            console.error("Error fetching card types:", error);
            await Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error fetching card types',
            });
        }

        if (data) {
            setCardType((prevCardTypes) => {
                const newCardTypes = [...prevCardTypes];
                newCardTypes[index] = data.map((item) => item.cardType);
                return newCardTypes;
            });

            // If there's only one card type, set it for the row
            if (data.length === 1) {
                setRows((prevRows) => {
                    const newRow = {...prevRows[index], cardType: data[0].cardType};
                    return [...prevRows.slice(0, index), newRow, ...prevRows.slice(index + 1)];
                });
            }
        }
    };

    const handleChange = async (index, field, value) => {

        const newRow = {...rows[index], [field]: value};
        setRows([...rows.slice(0, index), newRow, ...rows.slice(index + 1)]);

        if (field === columnType) {
            await updateCardType(index, value);
        }

    };

    const deleteRow = (index) => {
        setRows([...rows.slice(0, index), ...rows.slice(index + 1)]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!rows) {
            await Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'No data to insert',
            });
            return;
        }

        const rowsWithUserData = rows.map((row) => ({
            ...row,
            user_id: current_user_id,
            user_email: current_user_email,
            year: year,
            playerNameCardType: "",
        }));

        if (type === "hitter") {
            rowsWithUserData.forEach((row) => {
                row.playerNameCardType = `${row.hitterName.replace(/ /g, "")}${row.cardType}${year}`;
            });
        } else {
            rowsWithUserData.forEach((row) => {
                row.playerNameCardType = `${row.pitcherName.replace(/ /g, "")}${row.cardType}${year}`;
            });
        }

        const {data, error} = await supabase
            .from(insertTableName)
            .insert(rowsWithUserData)
            .limit(20);

        if (error === null) {
            Swal.fire({
                title: "Data inserted successfully",
                icon: "success",
                showCancelButton: true,
                confirmButtonText: "OK",
                cancelButtonText: "Back to previous page",
            }).then((result) => {
                if (result.isConfirmed) {
                    // handle OK button click
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    // handle "Back to previous page" button click
                    window.history.back();
                }
            });
        } else {
            console.error(error);
            await Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'No data to insert',
            });
        }
    };

    return (

        <div className="bulkAddStatsContainer">

            <Sidebar/>

            <div className="content">

                <h1>Add New {type.charAt(0).toUpperCase() + type.slice(1)} Stats</h1>

                <h3>Add Single Game Stats or Bulk Stats (Limit 20 Rows)</h3>

                <form onSubmit={handleSubmit} className="formContainer">

                    <TableContainer component={Paper} sx={{overflowX: 'auto'}}>
                        <Table>
                            <TableHead>
                                <TableRow sx={{textAlign: 'center'}}>
                                    <TableCell>Player</TableCell>
                                    <TableCell>Card Type</TableCell>
                                    {type === "hitter" ? (
                                        <>
                                            {addBulkDataHitterGameStatsInput.map((input) => (
                                                <TableCell key={input.id}>{input.label}</TableCell>
                                            ))}
                                        </>
                                    ) : (
                                        <>
                                            {addBulkDataPitcherGameStatsInput.map((input) => (
                                                <TableCell key={input.id}>{input.label}</TableCell>
                                            ))}
                                        </>
                                    )}
                                    <TableCell>Delete</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row, index) => {

                                    //console.log(row)

                                    return (
                                        <TableRow key={index}>
                                            <TableCell sx={{ width: '200px' }}>
                                                <Autocomplete
                                                    value={row[columnType]}
                                                    onChange={(event, newValue) => {
                                                        handleChange(index, columnType, newValue);
                                                    }}
                                                    options={players.map(player => player[columnType])}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="Select a player" />
                                                    )}
                                                    renderOption={(props, option) => <li {...props}>{option}</li>}
                                                    isOptionEqualToValue={(option, value) => option === value}
                                                />
                                            </TableCell>
                                            <TableCell sx={{ width: '200px' }}>
                                                {cardType[index] && cardType[index].length > 0 && (
                                                    <Select
                                                        value={row.cardType}
                                                        onChange={(e) => handleChange(index, "cardType", e.target.value)}
                                                        disabled={cardType[index].length === 1}
                                                    >
                                                        <MenuItem value="">Select one</MenuItem>
                                                        {cardType[index].map((cardType, i) => (
                                                            <MenuItem key={i} value={cardType}>
                                                                {cardType}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                )}
                                            </TableCell>
                                            {type === "hitter" ? (
                                                <>
                                                    {addBulkDataHitterGameStatsInput.map((input) => {
                                                        if (input.type === "select") {
                                                            return (
                                                                <TableCell key={input.id}>
                                                                    <Select
                                                                        id={input.id}
                                                                        onChange={(e) =>
                                                                            handleChange(index, input.id, e.target.value)
                                                                        }
                                                                        required
                                                                    >
                                                                        {input.options.map((option) => {
                                                                            //console.log(option)

                                                                            return (
                                                                                <MenuItem
                                                                                    key={option.value}
                                                                                    value={option.value}
                                                                                    disabled={option.value === "SelectOne"}
                                                                                    selected={option.value === "SelectOne"}
                                                                                >
                                                                                    {option.label}
                                                                                </MenuItem>
                                                                            )
                                                                        })}
                                                                    </Select>
                                                                </TableCell>
                                                            );
                                                        } else {
                                                            return (
                                                                <TableCell key={input.id}>
                                                                    <TextField
                                                                        type={input.type}
                                                                        placeholder={input.placeholder}
                                                                        value={row[input.id]}
                                                                        onChange={(e) => handleChange(
                                                                            index,
                                                                            input.id,
                                                                            input.type === "number"
                                                                                ? parseInt(e.target.value)
                                                                                : e.target.value
                                                                        )}
                                                                        required
                                                                        className="input-smaller"
                                                                        inputProps={{min: 0}}
                                                                    />
                                                                </TableCell>
                                                            );
                                                        }
                                                    })}
                                                </>
                                            ) : (
                                                <>
                                                    {addBulkDataPitcherGameStatsInput.map((input) => {
                                                        if (input.type === "select") {
                                                            return (
                                                                <TableCell key={input.id}>
                                                                    <Select
                                                                        id={input.id}
                                                                        onChange={(e) =>
                                                                            handleChange(index, input.id, e.target.value)
                                                                        }
                                                                        required
                                                                    >
                                                                        {input.options.map((option) => (
                                                                            <MenuItem
                                                                                key={option.value}
                                                                                value={option.value}
                                                                                disabled={option.value === "SelectOne"}
                                                                                selected={option.value === "SelectOne"}
                                                                            >
                                                                                {option.label}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </TableCell>
                                                            );
                                                        } else {
                                                            return (
                                                                <TableCell key={input.id}>
                                                                    <TextField
                                                                        type={input.type}
                                                                        placeholder={input.placeholder}
                                                                        value={row[input.id]}
                                                                        onChange={(e) => handleChange(
                                                                            index,
                                                                            input.id,
                                                                            input.type === "number"
                                                                                ? parseInt(e.target.value)
                                                                                : e.target.value
                                                                        )}
                                                                        required
                                                                        className="input-smaller"
                                                                        inputProps={{min: 0}}
                                                                    />
                                                                </TableCell>
                                                            );
                                                        }
                                                    })}
                                                </>
                                            )}
                                            <TableCell>
                                                <Button
                                                    className="btnDeletePlayer"
                                                    variant="outlined"
                                                    size="large"
                                                    aria-label="delete"
                                                    startIcon={
                                                        <DeleteForeverIcon/>
                                                    }
                                                    onClick={() => deleteRow(index)}
                                                >
                                                    Delete
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}


                            </TableBody>
                        </Table>
                    </TableContainer>

                    <div className="form-actions">
                        {type === "hitter" ? (
                            <>
                                <Button
                                    className="btnAddPlayer"
                                    variant="contained"
                                    type="button"
                                    onClick={addHitterRow}
                                >
                                    Add Player
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button
                                    className="btnAddPlayer"
                                    variant="contained"
                                    type="button"
                                    onClick={addPitcherRow}
                                >
                                    Add Player
                                </Button>
                            </>
                        )}
                        {
                            current_user_email === 'baseballstatstrackertestuser@gmail.com'
                                ? (
                                    <></>
                                ) : (
                                    <>
                                        <Button
                                            className="btnSubmitPlayer"
                                            variant="contained"
                                            color="success"
                                            type="submit"
                                        >
                                            Submit
                                        </Button>
                                    </>
                                )
                        }
                    </div>
                </form>


            </div>


        </div>

    )

}

export default BulkAddStats