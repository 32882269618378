import '../new.scss';

import { useContext, useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "../../../components/sidebar/sidebar";
import { AuthContext } from "../../../context/AuthContext";
import supabase from "../../../config/supabaseClient";
import Footer from "../../../components/footer/footer";
import Swal from 'sweetalert2'


const NewHitter = ({inputs, title}) => {

    const [data, setData] = useState({});
    const [previousTeamGameStats, setPreviousTeamGameStats] = useState({})
    const [totaledTeamGameStats, setTotaledTeamGameStats] = useState({})
    const [fetchError, setFetchError] = useState("")

    const navigate = useNavigate();
    const {year} = useParams();
    const {currentUser} = useContext(AuthContext);
    // console.log(currentUser)
    // console.log(currentUser)
    const current_user_email = currentUser.email

    const fetchPrevTeamGameStats = async () => {

        const {data, error} = await supabase
            .from('teamGameStats')
            .select()
            .eq('user_email', current_user_email)
            .eq("year", year)

        if (error) {
            setFetchError(error);
        }

        setPreviousTeamGameStats(data)

    }

    useEffect(() => {
        fetchPrevTeamGameStats();
    }, [current_user_email, year])

    const handleInput = (e) => {

        const id = e.target.id;
        const value = e.target.value

        // console.log(data)

        setData({...data, [id]: value});

        for (let i = 0; i < previousTeamGameStats.length; i++) {

            // console.log(previousTeamGameStats[i])
            // console.log(data)

            const gameRunsFor =  parseInt(data.gameRunsFor) + parseInt(previousTeamGameStats[i].gameRunsFor)
            const gameRunsAgainst = parseInt(data.gameRunsAgainst) + parseInt(previousTeamGameStats[i].gameRunsAgainst)
            const gameWinCounter =  parseInt(data.gameWinCounter) + parseInt(previousTeamGameStats[i].gameWinCounter)
            const gameLossCounter = parseInt(data.gameLossCounter) + parseInt(previousTeamGameStats[i].gameLossCounter)
            const hitsFor = parseInt(data.hitsFor) + parseInt(previousTeamGameStats[i].hitsFor)
            const hitsAgainst = parseInt(data.hitsAgainst) + parseInt(previousTeamGameStats[i].hitsAgainst)

            const awayWinCounter = parseInt(data.awayWinCounter) + parseInt(previousTeamGameStats[i].awayWinCounter)
            const awayLossCounter = parseInt(data.awayLossCounter) + parseInt(previousTeamGameStats[i].awayLossCounter)
            const homeWinCounter = parseInt(data.homeWinCounter) + parseInt(previousTeamGameStats[i].homeWinCounter)
            const homeLossCounter = parseInt(data.homeLossCounter) + parseInt(previousTeamGameStats[i].homeLossCounter)
            const dayWinCounter = parseInt(data.dayWinCounter) + parseInt(previousTeamGameStats[i].dayWinCounter)
            const dayLossCounter = parseInt(data.dayLossCounter) + parseInt(previousTeamGameStats[i].dayLossCounter)
            const nightWinCounter = parseInt(data.nightWinCounter) + parseInt(previousTeamGameStats[i].nightWinCounter)
            const nightLossCounter = parseInt(data.nightLossCounter) + parseInt(previousTeamGameStats[i].nightLossCounter)

            const results = {
                gameRunsFor,
                gameRunsAgainst,
                gameWinCounter,
                gameLossCounter,
                hitsFor,
                hitsAgainst,
                awayWinCounter,
                awayLossCounter,
                homeWinCounter,
                homeLossCounter,
                dayWinCounter,
                dayLossCounter,
                nightWinCounter,
                nightLossCounter
            }

            setTotaledTeamGameStats(results)
            // console.log(results)

            // need to make sure results doesn't have any null values

        }

        // console.log(data)

    }

    const handleAdd = async (e) => {

        e.preventDefault();

        try {

            const isThereNull = Object.values(totaledTeamGameStats).every(x => x === null);

            if (!isThereNull) {

                const { error } = await supabase
                    .from('teamGameStats')
                    .update({
                        ...totaledTeamGameStats
                    })
                    .eq("user_email", current_user_email)
                    .eq("year", year)

                if (!error) {

                    const { error } = await supabase
                        .from('teamGameStatLog')
                        .insert({
                            user_id: currentUser.id,
                            gameNumber: data.gameNumber,
                            timestamp: data.timestamp,
                            gameLocation: data.gameLocation,
                            gameTime: data.gameTime,
                            ratingBefore: data.ratingBefore,
                            ratingAfter: data.ratingAfter,
                            hitsFor: data.hitsFor,
                            hitsAgainst: data.hitsAgainst,
                            rageQuit: data.rageQuit,
                            seasonNum: data.seasonNum,
                            gameRunsFor: data.gameRunsFor,
                            gameRunsAgainst: data.gameRunsAgainst,
                            gameWinCounter: data.gameWinCounter,
                            gameLossCounter: data.gameLossCounter,
                            user_email: current_user_email,
                            year: year,
                            gameModeType: data.gameModeType
                        })

                    if (error) {
                        setFetchError(error);
                    }

                }

                await Swal.fire({
                    title: "New game data successfully",
                    icon: "success",
                    showCancelButton: true,
                    confirmButtonText: "OK",
                    cancelButtonText: "Back to previous page",
                }).then((result) => {
                    if (result.isConfirmed) {
                        // handle OK button click
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        // handle "Back to previous page" button click
                        window.history.back();
                    }
                });

            }
            else {

                await Swal.fire({
                    title: 'Error!',
                    text: 'There is a NULL value in one your hitter\'s stats, please contact Alex for help.',
                    icon: 'error',
                    confirmButtonText: 'Cool'
                })

            }

        } catch (error) {
            console.log(error)
            setFetchError(error)
        }

    }

    return (
        <div className="new">
            <Sidebar/>
            <div className="newContainer">
                <div className="top">
                    <h1>{title}</h1>
                </div>
                <div className="bottom">
                    <div className="content">
                        {fetchError && (<p>{fetchError}</p>)}
                        <form onSubmit={handleAdd}>
                            {inputs.map((input) => (
                                <div className="formInput" key={input.id}>
                                    <label htmlFor={input.id}>{input.label}</label>
                                    {input.type === "select" ? (
                                        <select
                                            id={input.id}
                                            onChange={handleInput}
                                            required
                                        >
                                            {input.options.map((option) => (
                                                <option
                                                    key={option.value}
                                                    value={option.value}
                                                    disabled={option.value === "SelectOne"}
                                                    selected={option.value === "SelectOne"}
                                                >
                                                    {option.label}
                                                </option>
                                            ))}
                                        </select>
                                    ) : (
                                        <input
                                            id={input.id}
                                            type={input.type}
                                            placeholder={input.placeholder}
                                            onChange={handleInput}
                                            required
                                        />
                                    )}
                                </div>
                            ))}

                            {
                                current_user_email === 'baseballstatstrackertestuser@gmail.com'
                                    ? (
                                        <h3>You cannot submit that content</h3>
                                    ) : (
                                        <button type="submit">Send</button>
                                    )
                            }
                        </form>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default NewHitter
