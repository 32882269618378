import Sidebar from "../../../components/sidebar/sidebar";
import ModeSpecificStats from "../../../components/modeSpecificStats/modeSpecificStats";
import Footer from "../../../components/footer/footer";

const CoOpStats = ({title}) => {

    return (

        <div className="gameModeContainer">

            <Sidebar/>

            <div className="content">

                <h1 className="title">{title}</h1>

                <ModeSpecificStats type="coopRanked"/>

                <Footer/>

            </div>

        </div>

    )

}

export default CoOpStats
