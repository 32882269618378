import './single.scss';

import Sidebar from "../../components/sidebar/sidebar";
import { Link, useParams } from "react-router-dom";
import SinglePlayerTable from "../../components/singlePlayerTable/SinglePlayerTable";
import Profile from "../../components/profile/Profile";
import Footer from "../../components/footer/footer";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";

const Single = () => {

    const {year} = useParams();
    const {type} = useParams();

    const {playerName} = useParams();
    const {playerCardType} = useParams();

    const {currentUser} = useContext(AuthContext);
    const current_user_email = currentUser.email

    const player = (playerName + playerCardType + year).replace(/\s/g, '')

    return (
        <div className="single">
            <Sidebar/>
            <div className="singleContainer">
                <div className="top">
                    <div className="profileContent">
                        <div className="item">
                            <Profile player={player} type={type}/>
                        </div>
                    </div>
                </div>
                <div className="bottom">
                    <h1 className="title">Recent Games Played</h1>
                    <SinglePlayerTable/>
                </div>
                <Footer/>
            </div>
        </div>
    );
}

export default Single
