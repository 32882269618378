import '../new.scss';
import Sidebar from "../../../components/sidebar/sidebar";
import { useContext, useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import supabase from "../../../config/supabaseClient";
import Footer from "../../../components/footer/footer";
import Swal from 'sweetalert2'

const NewHitterGameStats = ({inputs, title}) => {

    const [data, setData] = useState({});
    const [previousPlayerStats, setPreviousPlayerStats] = useState({})
    const [totaledPlayerStats, setTotaledPlayerStats] = useState({})
    const [fetchError, setFetchError] = useState("")

    const navigate = useNavigate();
    const {year} = useParams();
    const {currentUser} = useContext(AuthContext);
    const current_user_email = currentUser.email
    const {playerName} = useParams();
    const {playerCardType} = useParams();

    const playerFullName = playerName + playerCardType + year

    const playerNameNoSpaces = playerFullName.replace(/\s/g, '')
    // console.log(playerNameNoSpaces)
    // console.log(title)

    const fetchOldPlayerStats = async () => {

        const {data, error} = await supabase
            .from('hitters')
            .select()
            .like('playerNameCardType', playerNameNoSpaces)
            .eq("user_email", current_user_email)
            .eq("year", year)

        if (error) {
            setFetchError(error);
        }

        setPreviousPlayerStats(data)

    }

    useEffect(() => {
        fetchOldPlayerStats();
    }, [current_user_email, year])

    // console.log(previousPlayerStats)

    // idea: pull existing data and add it to new data before inserting
    const handleInput = async (e) => {

        const id = e.target.id;
        const value = e.target.value

        setData({...data, [id]: value});

        // console.log(data)
        // console.log(previousPlayerStats)

        for (let i = 0; i < previousPlayerStats.length; i++) {

            // console.log(previousPlayerStats[i])

            // general
            const hitterGamesPlayed = parseInt(data.hitterGamesPlayed) + parseInt(previousPlayerStats[i].hitterGamesPlayed)

            // hitters
            const hitterAppearances = parseInt(data.hitterAppearances) + parseInt(previousPlayerStats[i].hitterAppearances)
            const hitterAtBats = parseInt(data.hitterAtBats) + parseInt(previousPlayerStats[i].hitterAtBats)
            const hitterHits = parseInt(data.hitterHits) + parseInt(previousPlayerStats[i].hitterHits)
            const hitterSingles = parseInt(data.hitterSingles) + parseInt(previousPlayerStats[i].hitterSingles)
            const hitterDoubles = parseInt(data.hitterDoubles) + parseInt(previousPlayerStats[i].hitterDoubles)
            const hitterTriples = parseInt(data.hitterTriples) + parseInt(previousPlayerStats[i].hitterTriples)
            const hitterHomeRuns = parseInt(data.hitterHomeRuns) + parseInt(previousPlayerStats[i].hitterHomeRuns)
            const hitterRunsBattedIn = parseInt(data.hitterRunsBattedIn) + parseInt(previousPlayerStats[i].hitterRunsBattedIn)
            const hitterWalks = parseInt(data.hitterWalks) + parseInt(previousPlayerStats[i].hitterWalks)
            const hitterStrikeouts = parseInt(data.hitterWalks) + parseInt(previousPlayerStats[i].hitterWalks)

            const results = {
                hitterGamesPlayed,
                hitterAppearances,
                hitterAtBats,
                hitterHits,
                hitterSingles,
                hitterDoubles,
                hitterTriples,
                hitterHomeRuns,
                hitterRunsBattedIn,
                hitterWalks,
                hitterStrikeouts
            }

            // console.log(results)

            setTotaledPlayerStats(results)

        }

    }

    // idea: pull existing data and add it to new data before inserting
    // console.log(year)
    // console.log(data)
    const handleAdd = async (e) => {

        e.preventDefault();
        // const playerNamePlusYear = playerNameNoSpaces
        // console.log(playerNamePlusYear)
        // console.log(totaledPlayerStats)

        try {

            const isThereNull = Object.values(totaledPlayerStats).every(x => x === null);

            if (!isThereNull) {

                const {error} = await supabase
                    .from('hitters')
                    .update({
                        ...totaledPlayerStats,
                    })
                    .eq("playerNameCardType", playerNameNoSpaces)
                    .eq("user_email", current_user_email)
                    .eq("year", year)

                if (!error) {

                    const {error} = await supabase
                        .from('hittersGameStats')
                        .insert({
                            user_id: currentUser.id,
                            hitterName: playerName,
                            cardType: playerCardType,
                            ...data,
                            playerNameCardType: playerNameNoSpaces,
                            user_email: current_user_email,
                            year: year
                        })

                    if (error) {
                        setFetchError(error)
                    }

                }

                await Swal.fire({
                    title: "New hitter inserted successfully",
                    icon: "success",
                    showCancelButton: true,
                    confirmButtonText: "OK",
                    cancelButtonText: "Back to previous page",
                }).then((result) => {
                    if (result.isConfirmed) {
                        // handle OK button click
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        // handle "Back to previous page" button click
                        window.history.back();
                    }
                });

            } // end of null check
            else {

                await Swal.fire({
                    title: 'Error!',
                    text: 'There is a NULL value in one your hitter\'s stats, please contact Alex for help.',
                    icon: 'error',
                    confirmButtonText: 'Cool'
                })

            }

        } catch (error) {
            console.log(error)
        }

    }

    return (
        <div className="new">
            <Sidebar/>
            <div className="newContainer">
                <div className="top">
                    <h1>{title} for <span style={{fontStyle: 'italic'}}>{playerName}</span></h1>
                </div>
                <div className="bottom">
                    <div className="content">
                        {fetchError && (<p>{fetchError}</p>)}
                        <form onSubmit={handleAdd}>
                            {inputs.map((input) => (
                                <div className="formInput" key={input.id}>
                                    <label htmlFor={input.id}>{input.label}</label>
                                    {input.type === "select" ? (
                                        <select
                                            id={input.id}
                                            onChange={handleInput}
                                            required
                                        >
                                            {input.options.map((option) => (
                                                <option
                                                    key={option.value}
                                                    value={option.value}
                                                    disabled={option.value === "SelectOne"}
                                                    selected={option.value === "SelectOne"}
                                                >
                                                    {option.label}
                                                </option>
                                            ))}
                                        </select>
                                    ) : (
                                        <input
                                            id={input.id}
                                            type={input.type}
                                            placeholder={input.placeholder}
                                            onChange={handleInput}
                                            required
                                        />
                                    )}
                                </div>
                            ))}
                            {
                                current_user_email === 'baseballstatstrackertestuser@gmail.com'
                                    ? (
                                        <h3>You cannot submit that content</h3>
                                    ) : (
                                        <button type="submit">Send</button>
                                    )
                            }
                        </form>

                    </div>
                </div>
                <Footer/>
            </div>
        </div>
    );
}

export default NewHitterGameStats
