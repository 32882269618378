import './welcome.scss'
import { useContext, useEffect, useState } from "react";
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import { Link, useNavigate } from "react-router-dom";
import supabase from "../../config/supabaseClient";
import { AuthContext } from "../../context/AuthContext";

import { Grid } from "@mui/material";

const Welcome = () => {

    const [userInfo, getUserInfo] = useState([]);
    const [fetchError, setFetchError] = useState("")

    const {currentUser} = useContext(AuthContext);
    const current_user_email = currentUser.email

    useEffect(() => {
        const userInfo = JSON.parse(localStorage.getItem('user'));

        if (userInfo) {
            getUserInfo(userInfo)
        }
    }, []);

    // console.log(userInfo)

    const navigate = useNavigate();

    const handleClick = async (event) => {

        const year = event.target.id

        if (year === "2022") {

            // select teamGameStats table, if row with user_id and email exists, move on
            const {data, error} = await supabase
                .from('teamGameStats')
                .select()
                .eq("user_email", current_user_email)
                .eq("year", year)

            if (error) {
                setFetchError(error)
                console.log(error)
            }
            if (data.length !== 0) {
                console.log("User has a table")
                setFetchError(null)
                navigate(`/${year}/dashboard`);
            }

            // else insert row into teamGameStats table with user_id, email, year filled in. everything else has 0s
            if (data.length === 0) {

                console.log("empty array")

                try {

                    const {error} = await supabase
                        .from('teamGameStats')
                        .insert({
                            user_id: currentUser.id,
                            user_email: current_user_email,
                            year: year,
                            gameRunsFor: 0,
                            gameRunsAgainst: 0,
                            gameWinCounter: 0,
                            gameLossCounter: 0,
                            hitsFor: 0,
                            hitsAgainst: 0,
                            awayWinCounter: 0,
                            awayLossCounter: 0,
                            homeWinCounter: 0,
                            homeLossCounter: 0,
                            dayWinCounter: 0,
                            dayLossCounter: 0,
                            nightLossCounter: 0,
                            nightWinCounter: 0
                        })

                    if (error) {
                        setFetchError(error)
                        console.log(error)
                    }

                    navigate(`/${year}/dashboard`);

                } catch (error) {
                    console.log(error)
                    setFetchError(error)
                }

            }

        } else if (year === "2023") {

            // select teamGameStats table, if row with user_id and email exists, move on
            const {data, error} = await supabase
                .from('teamGameStats')
                .select()
                .eq("user_email", current_user_email)
                .eq("year", year)

            if (error) {
                setFetchError('Some error occurred')
                console.log(error)
            }
            if (data.length !== 0) {
                console.log("User has a table")
                setFetchError(null)
                navigate(`/${year}/dashboard`);
            }

            // else insert row into teamGameStats table with user_id, email, year filled in. everything else has 0s
            if (data.length === 0) {

                console.log("empty array")

                try {

                    const {error} = await supabase
                        .from('teamGameStats')
                        .insert({
                            user_id: currentUser.id,
                            user_email: current_user_email,
                            year: year,
                            gameRunsFor: 0,
                            gameRunsAgainst: 0,
                            gameWinCounter: 0,
                            gameLossCounter: 0,
                            hitsFor: 0,
                            hitsAgainst: 0,
                            awayWinCounter: 0,
                            awayLossCounter: 0,
                            homeWinCounter: 0,
                            homeLossCounter: 0,
                            dayWinCounter: 0,
                            dayLossCounter: 0,
                            nightLossCounter: 0,
                            nightWinCounter: 0
                        })

                    if (error) {
                        setFetchError(error)
                        console.log(error)
                    }

                    navigate(`/${year}/dashboard`);

                } catch (error) {
                    console.log(error)
                    setFetchError(error)
                }

            }

        }

    }

    useEffect(() => {
        handleClick();
    });

    return (
        <div className="welcome">
            <div className="overlay"></div>
            <video
                src="https://apk-spaces-bucket.sfo3.cdn.digitaloceanspaces.com/MLBTS-Stats-Project%2FWelcomePage-HR-Montage.mp4"
                autoPlay
                muted
                loop
                playsInline
            />

            <div className="content">
                <div className="title">
                    <div className="left-title">
                        <Grid
                            container
                            className="title-grid"
                        >
                            <Grid item>
                                <p>Welcome</p>
                            </Grid>
                            <Grid item>
                                <EmojiPeopleIcon fontSize="large" className="icon-title"/>
                            </Grid>
                            <Grid item>
                                <p className="email-title">{userInfo.email}</p>
                            </Grid>
                        </Grid>
                    </div>
                    {
                        current_user_email === 'baseballstatstrackertestuser@gmail.com'
                            ? (
                                <div className="right-title">
                                    Please click on the picture below to see MLBTS 22 Test Stats.
                                </div>
                            ) : (
                                <>
                                    <div className="right-title">
                                        Please click on the picture below to see MLBTS 23 Stats.
                                    </div>
                                </>
                            )
                    }
                </div>
                <div className="bottom-content">

                    {
                        current_user_email === 'baseballstatstrackertestuser@gmail.com'
                            ? (
                                <>
                                    <div className="left-game link" onClick={handleClick}>
                                        <h2>2022 MLBTS Online Stats</h2>
                                        <img
                                            src="https://apk-spaces-bucket.sfo3.cdn.digitaloceanspaces.com/MLBTS-Stats-Project%2Fmlbtheshow22.png"
                                            alt="mlbts 22 main logo"
                                            className="gameImage"
                                            id="2022"
                                        />
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="right-game link" onClick={handleClick}>
                                        <h2>2023 MLBTS Online Stats</h2>
                                        <img
                                            src="https://apk-spaces-bucket.sfo3.cdn.digitaloceanspaces.com/MLBTS-Stats-Project%2Fmlbtheshow23.jpg"
                                            alt="mlbts 23 main logo"
                                            className="gameImage"
                                            id="2023"
                                        />
                                    </div>
                                </>
                            )
                    }
                    {fetchError && (<p>{fetchError}</p>)}
                </div>
            </div>
        </div>
    );
}

export default Welcome;