import '../new.scss';
import Sidebar from "../../../components/sidebar/sidebar";
import { useContext, useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import supabase from "../../../config/supabaseClient";
import Swal from 'sweetalert2'
import Footer from "../../../components/footer/footer";

const NewPitcherGameStats = ({inputs, title}) => {

    const [data, setData] = useState({});
    const [previousPlayerStats, setPreviousPlayerStats] = useState({})
    const [totaledPlayerStats, setTotaledPlayerStats] = useState({})
    const [fetchError, setFetchError] = useState("")

    const navigate = useNavigate();
    const {year} = useParams();
    const {playerName} = useParams();
    const {playerCardType} = useParams();

    const {currentUser} = useContext(AuthContext);
    const current_user_email = currentUser.email

    // console.log(playerName)

    const playerFullName = playerName + playerCardType + year

    const playerNameNoSpaces = playerFullName.replace(/\s/g, '')
    // console.log(playerNameNoSpaces)
    // console.log(title)

    const fetchOldPlayerStats = async () => {

        const {data, error} = await supabase
            .from('pitchers')
            .select()
            .like('playerNameCardType', playerNameNoSpaces)
            .eq("user_email", current_user_email)
            .eq("year", year)

        if (error) {
            setFetchError(error);
        }

        setPreviousPlayerStats(data)

    }

    useEffect(() => {
        fetchOldPlayerStats();
    }, [current_user_email, year])

    // console.log(previousPlayerStats)

    // idea: pull existing data and add it to new data before inserting
    const handleInput = async (e) => {

        const id = e.target.id;
        const value = e.target.value

        setData({...data, [id]: value});

        // console.log(data)
        // console.log(previousPlayerStats)

        for (let i = 0; i < previousPlayerStats.length; i++) {

            // console.log(previousPlayerStats[i])

            // general
            const pitcherGamesPlayed = parseInt(data.pitcherGamesPlayed) + parseInt(previousPlayerStats[i].pitcherGamesPlayed)

            // pitchers
            const pitcherInningsPitched = parseInt(data.pitcherInningsPitched) + parseInt(previousPlayerStats[i].pitcherInningsPitched)
            const pitcherWins = parseInt(data.pitcherWins) + parseInt(previousPlayerStats[i].pitcherWins)
            const pitcherLosses = parseInt(data.pitcherLosses) + parseInt(previousPlayerStats[i].pitcherLosses)
            const pitcherSaves = parseInt(data.pitcherSaves) + parseInt(previousPlayerStats[i].pitcherSaves)
            const pitcherStrikeouts = parseInt(data.pitcherStrikeouts) + parseInt(previousPlayerStats[i].pitcherStrikeouts)
            const pitcherWalks = parseInt(data.pitcherWalks) + parseInt(previousPlayerStats[i].pitcherWalks)
            const pitcherHits = parseInt(data.pitcherHits) + parseInt(previousPlayerStats[i].pitcherHits)
            const pitcherEarnedRuns = parseInt(data.pitcherEarnedRuns) + parseInt(previousPlayerStats[i].pitcherEarnedRuns)
            const pitcherHomeruns = parseInt(data.pitcherHomeruns) + parseInt(previousPlayerStats[i].pitcherHomeruns)
            const pitcherGamesStarted = parseInt(data.pitcherGamesStarted) + parseInt(previousPlayerStats[i].pitcherGamesStarted)
            const pitcherCompleteGames = parseInt(data.pitcherCompleteGames) + parseInt(previousPlayerStats[i].pitcherCompleteGames)
            const pitcherShutouts = parseInt(data.pitcherShutouts) + parseInt(previousPlayerStats[i].pitcherShutouts)
            const pitcherQualityStarts = parseInt(data.pitcherQualityStarts) + parseInt(previousPlayerStats[i].pitcherQualityStarts)

            const results = {
                pitcherGamesPlayed,
                pitcherInningsPitched,
                pitcherWins,
                pitcherLosses,
                pitcherSaves,
                pitcherStrikeouts,
                pitcherWalks,
                pitcherHits,
                pitcherEarnedRuns,
                pitcherHomeruns,
                pitcherGamesStarted,
                pitcherCompleteGames,
                pitcherShutouts,
                pitcherQualityStarts
            }

            // console.log(results)

            setTotaledPlayerStats(results)

        }

    }

    // idea: pull existing data and add it to new data before inserting
    // console.log(year)

    const handleAdd = async (e) => {

        e.preventDefault();
        // const playerNamePlusYear = playerNameNoSpaces
        // console.log(playerNamePlusYear)
        // console.log(totaledPlayerStats)

        try {

            const isThereNull = Object.values(totaledPlayerStats).every(x => x === null);

            if (!isThereNull) {

                const {error} = await supabase
                    .from('pitchers')
                    .update({
                        ...totaledPlayerStats,
                    })
                    .eq("playerNameCardType", playerNameNoSpaces)
                    .eq("user_email", current_user_email)
                    .eq("year", year)

                if (!error) {

                    const {error} = await supabase
                        .from('pitchersGameStats')
                        .insert({
                            user_id: currentUser.id,
                            pitcherName: playerName,
                            cardType: playerCardType,
                            ...data,
                            playerNameCardType: playerNameNoSpaces,
                            user_email: current_user_email,
                            year: year
                        })

                    if (error) {
                        setFetchError(error)
                    }

                }

                navigate(-1)

            } else {

                await Swal.fire({
                    title: 'Error!',
                    text: 'There is a NULL value in one your hitter\'s stats, please contact Alex for help.',
                    icon: 'error',
                    confirmButtonText: 'Cool'
                })

            }

        } catch (error) {
            console.log(error)
        }

    }

    return (
        <div className="new">
            <Sidebar/>
            <div className="newContainer">
                <div className="top">
                    <h1>{title} for <span style={{fontStyle: 'italic'}}>{playerName}</span></h1>
                </div>
                <div className="bottom">
                    <div className="content">
                        {fetchError && (<p>{fetchError}</p>)}
                        <form onSubmit={handleAdd}>
                            {inputs.map((input) => (
                                <div className="formInput" key={input.id}>
                                    <label htmlFor={input.id}>{input.label}</label>
                                    {input.type === "select" ? (
                                        <select
                                            id={input.id}
                                            onChange={handleInput}
                                            required
                                        >
                                            {input.options.map((option) => (
                                                <option
                                                    key={option.value}
                                                    value={option.value}
                                                    disabled={option.value === "SelectOne"}
                                                    selected={option.value === "SelectOne"}
                                                >
                                                    {option.label}
                                                </option>
                                            ))}
                                        </select>
                                    ) : (
                                        <input
                                            id={input.id}
                                            type={input.type}
                                            placeholder={input.placeholder}
                                            onChange={handleInput}
                                            required
                                        />
                                    )}
                                </div>
                            ))}

                            {
                                current_user_email === 'baseballstatstrackertestuser@gmail.com'
                                    ? (
                                        <h3>You cannot submit that content</h3>
                                    ) : (
                                        <button type="submit">Send</button>
                                    )
                            }
                        </form>
                    </div>
                </div>
                <Footer/>
            </div>
        </div>
    );
}

export default NewPitcherGameStats
