import './sidebar.scss'

import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'

import LogoutIcon from '@mui/icons-material/Logout';
import { useContext, useState } from "react";
import { DarkModeContext } from "../../context/darkModeContext";
import { styled, useTheme } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { grey, orange, red } from '@mui/material/colors';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import SportsBaseballIcon from '@mui/icons-material/SportsBaseball';
import GroupIcon from '@mui/icons-material/Group';
import SportsCricketIcon from '@mui/icons-material/SportsCricket';
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse, List, useMediaQuery } from "@mui/material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DashboardIcon from '@mui/icons-material/Dashboard';
import StarsIcon from '@mui/icons-material/Stars';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import { AuthContext } from "../../context/AuthContext";

const Sidebar = () => {

    const {currentUser} = useContext(AuthContext);
    const current_user_email = currentUser.email

    const location = useLocation();

    const {dispatch} = useContext(DarkModeContext);

    const navigate = useNavigate();

    const handleLogout = async () => {

        localStorage.removeItem("user")

        navigate("/");

    }

    const drawerWidth = 300;

    const openedMixin = (theme) => ({
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        overflowX: 'hidden',
    });

    const closedMixin = (theme) => ({
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: `calc(${theme.spacing(7)} + 1px)`,
        [theme.breakpoints.up('sm')]: {
            width: `calc(${theme.spacing(8)} + 1px)`,
        },
    });

    const DrawerHeader = styled('div')(({theme}) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    }));

    const AppBar = styled(MuiAppBar, {
        shouldForwardProp: (prop) => prop !== 'open',
    })(({theme, open}) => ({
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
    }));

    const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
        ({theme, open}) => ({
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
            boxSizing: 'border-box',
            ...(open && {
                ...openedMixin(theme),
                '& .MuiDrawer-paper': openedMixin(theme),
            }),
            ...(!open && {
                ...closedMixin(theme),
                '& .MuiDrawer-paper': closedMixin(theme),
            }),
        }),
    );

    const {year} = useParams();
    const theme = useTheme();

    const isMobile = useMediaQuery('(max-width:600px)');

    // open and setOpen is for the drawer to the entire sidebar
    const [open, setOpen] = useState(!isMobile);

    const [openSubMenu22, setOpenSubMenu22] = useState(true);
    const [openSubMenu23, setOpenSubMenu23] = useState(true);
    const [openCustomStatSubMenu22, setCustomStatsOpenSubMenu22] = useState(true);
    const [openCustomStatSubMenu23, setCustomStatsOpenSubMenu23] = useState(true);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleClick22 = () => {
        setOpenSubMenu22(!openSubMenu22);
    };
    const handleClick23 = () => {
        setOpenSubMenu23(!openSubMenu23);
    };

    const handleClickForCustomStats22 = () => {
        setCustomStatsOpenSubMenu22(!openCustomStatSubMenu22);
    };

    const handleClickForCustomStats23 = () => {
        setCustomStatsOpenSubMenu23(!openCustomStatSubMenu23)
    }

    const CustomListItem = styled(ListItem)(({theme, selected}) => ({
        backgroundColor: selected ? theme.palette.action.selected : "inherit",
        "& .MuiListItemText-primary": {
            color: selected ? "#7F669D" : "#7F669D",
            fontWeight: selected ? "bold" : "normal",
            fontSize: selected ? "17px" : "16px",
        },
        "&:hover": {
            backgroundColor: theme.palette.action.hover,
            "& .MuiListItemText-primary": {
                color: "#7F669D",
            },
        },
        "&.Mui-selected": {
            color: '#BEDCFA !important', // use !important here
            backgroundColor: `#7F669D !important`,
            "& .MuiListItemText-primary": {
                color: "#BEDCFA",
                fontWeight: "bold",
            },
            "& .MuiListItemIcon-root, .MuiTypography-root, .MuiSvgIcon-root": {
                color: "#BEDCFA !important",
            },
        },
    }));

    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <AppBar position="fixed" open={open}>
                <Toolbar>
                    <IconButton
                        color="white"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            ...(open && {display: 'none'}),
                            color: grey
                        }}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Typography variant="h6" noWrap component="div">
                        <Link to="/welcome" className="navBarTitle"
                              style={{textDecoration: "none", textAlign: "center", color: "black"}}>
                            MLBTS Stat Tracker
                        </Link>
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open} className="sidebarDrawer">
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
                    </IconButton>
                </DrawerHeader>
                <Divider/>

                {
                    current_user_email === 'baseballstatstrackertestuser@gmail.com'
                        ? (
                            <>
                                <ListItem disablePadding>
                                    <ListItemButton onClick={handleClick22}>
                                        <ListItemIcon>
                                            <LooksTwoIcon/><LooksTwoIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary="22 Stats"/>
                                    </ListItemButton>
                                    {openSubMenu22 ? <ExpandLess/> : <ExpandMore/>}
                                </ListItem>

                                <Collapse in={openSubMenu22} timeout="auto" unmountOnExit>

                                    <List component="div" disablePadding>
                                        <CustomListItem
                                            button
                                            component={Link}
                                            to="/2022/dashboard"
                                            selected={location.pathname === "/2022/dashboard"}
                                        >
                                            <ListItemIcon>
                                                <DashboardIcon/>
                                            </ListItemIcon>
                                            <ListItemText primary="2022 Dashboard"/>
                                        </CustomListItem>

                                        <CustomListItem
                                            button
                                            component={Link}
                                            to="/2022/hitter"
                                            selected={location.pathname === "/2022/hitter"}
                                        >
                                            <ListItemIcon>
                                                <SportsCricketIcon/>
                                            </ListItemIcon>
                                            <ListItemText primary="All Hitters"/>
                                        </CustomListItem>

                                        <CustomListItem
                                            button
                                            component={Link}
                                            to="/2022/pitcher"
                                            selected={location.pathname === "/2022/pitcher"}
                                        >
                                            <ListItemIcon>
                                                <SportsBaseballIcon/>
                                            </ListItemIcon>
                                            <ListItemText primary="All Pitchers"/>
                                        </CustomListItem>

                                        <CustomListItem
                                            button
                                            component={Link}
                                            to="/2022/teamGameStats"
                                            selected={location.pathname === "/2022/teamGameStats"}
                                        >
                                            <ListItemIcon>
                                                <WorkspacesIcon/>
                                            </ListItemIcon>
                                            <ListItemText primary="Team Game Stats"/>
                                        </CustomListItem>

                                        <Divider className="short-divider" sx={{
                                            ml: 2
                                        }}/>

                                        <ListItem disablePadding>
                                            <ListItemButton onClick={handleClickForCustomStats22}>
                                                <ListItemText primary="22 Mode Specific Player Stats"/>
                                            </ListItemButton>
                                            {openCustomStatSubMenu22 ? <ExpandLess/> : <ExpandMore/>}
                                        </ListItem>

                                        <Collapse in={openCustomStatSubMenu22} timeout="auto" unmountOnExit>

                                            <CustomListItem
                                                button
                                                component={Link}
                                                to="/2022/stats/rankedStats"
                                                selected={location.pathname === "/2022/stats/rankedStats"}
                                            >
                                                <ListItemIcon>
                                                    <MilitaryTechIcon/>
                                                </ListItemIcon>
                                                <ListItemText primary="Solo Ranked"/>
                                            </CustomListItem>

                                            <CustomListItem
                                                button
                                                component={Link}
                                                to="/2022/stats/coopStats"
                                                selected={location.pathname === "/2022/stats/coopStats"}
                                            >
                                                <ListItemIcon>
                                                    <GroupIcon/>
                                                </ListItemIcon>
                                                <ListItemText primary="Co-Op Ranked"/>
                                            </CustomListItem>

                                            <CustomListItem
                                                button
                                                component={Link}
                                                to="/2022/stats/customLeagueStats"
                                                selected={location.pathname === "/2022/stats/customLeagueStats"}
                                            >
                                                <ListItemIcon>
                                                    <Diversity3Icon/>
                                                </ListItemIcon>
                                                <ListItemText primary="Custom League"/>
                                            </CustomListItem>

                                            <CustomListItem
                                                button
                                                component={Link}
                                                to="/2022/stats/brStats"
                                                selected={location.pathname === "/2022/stats/brStats"}
                                            >
                                                <ListItemIcon>
                                                    <StarsIcon/>
                                                </ListItemIcon>
                                                <ListItemText primary="Battle Royale"/>
                                            </CustomListItem>

                                        </Collapse>

                                    </List>

                                </Collapse>
                            </>
                        ) : (
                            <>
                                {/* 2023 */}
                                <ListItem disablePadding>
                                    <ListItemButton onClick={handleClick23}>
                                        <ListItemIcon className="gameYear">
                                            <LooksTwoIcon/><Looks3Icon/>
                                        </ListItemIcon>
                                        <ListItemText primary="23 Stats"/>
                                    </ListItemButton>
                                    {openSubMenu23 ? <ExpandLess/> : <ExpandMore/>}
                                </ListItem>
                                <Collapse in={openSubMenu23} timeout="auto" unmountOnExit>

                                    <List component="div" disablePadding>

                                        <CustomListItem
                                            button
                                            component={Link}
                                            to="/2023/dashboard"
                                            selected={location.pathname === "/2023/dashboard"}
                                        >
                                            <ListItemIcon>
                                                <DashboardIcon/>
                                            </ListItemIcon>
                                            <ListItemText primary="2023 Dashboard"/>
                                        </CustomListItem>

                                        <CustomListItem
                                            button
                                            component={Link}
                                            to="/2023/hitter"
                                            selected={location.pathname === "/2023/hitter"}
                                        >
                                            <ListItemIcon>
                                                <SportsCricketIcon/>
                                            </ListItemIcon>
                                            <ListItemText primary="All Hitters"/>
                                        </CustomListItem>

                                        <CustomListItem
                                            button
                                            component={Link}
                                            to="/2023/pitcher"
                                            selected={location.pathname === "/2023/pitcher"}
                                        >
                                            <ListItemIcon>
                                                <SportsBaseballIcon/>
                                            </ListItemIcon>
                                            <ListItemText primary="All Pitchers"/>
                                        </CustomListItem>

                                        <CustomListItem
                                            button
                                            component={Link}
                                            to="/2023/teamGameStats"
                                            selected={location.pathname === "/2023/teamGameStats"}
                                        >
                                            <ListItemIcon>
                                                <WorkspacesIcon/>
                                            </ListItemIcon>
                                            <ListItemText primary="Team Game Stats"/>
                                        </CustomListItem>

                                    </List>

                                    <Divider sx={{
                                        ml: 2
                                    }}/>

                                    <ListItem disablePadding>
                                        <ListItemButton onClick={handleClickForCustomStats23}>
                                            <ListItemText primary="23 Mode Specific Player Stats"/>
                                        </ListItemButton>
                                        {openCustomStatSubMenu23 ? <ExpandLess/> : <ExpandMore/>}
                                    </ListItem>

                                    <Collapse in={openCustomStatSubMenu23} timeout="auto" unmountOnExit>

                                        <CustomListItem
                                            button
                                            component={Link}
                                            to="/2023/stats/rankedStats"
                                            selected={location.pathname === "/2023/stats/rankedStats"}
                                        >
                                            <ListItemIcon>
                                                <MilitaryTechIcon/>
                                            </ListItemIcon>
                                            <ListItemText primary="Solo Ranked"/>
                                        </CustomListItem>

                                        <CustomListItem
                                            button
                                            component={Link}
                                            to="/2023/stats/coopStats"
                                            selected={location.pathname === "/2023/stats/coopStats"}
                                        >
                                            <ListItemIcon>
                                                <GroupIcon/>
                                            </ListItemIcon>
                                            <ListItemText primary="Co-Op Ranked"/>
                                        </CustomListItem>

                                        <CustomListItem
                                            button
                                            component={Link}
                                            to="/2023/stats/customLeagueStats"
                                            selected={location.pathname === "/2023/stats/customLeagueStats"}
                                        >
                                            <ListItemIcon>
                                                <Diversity3Icon/>
                                            </ListItemIcon>
                                            <ListItemText primary="Custom League"/>
                                        </CustomListItem>

                                        <CustomListItem
                                            button
                                            component={Link}
                                            to="/2023/stats/brStats"
                                            selected={location.pathname === "/2023/stats/brStats"}
                                        >
                                            <ListItemIcon>
                                                <StarsIcon/>
                                            </ListItemIcon>
                                            <ListItemText primary="Battle Royale"/>
                                        </CustomListItem>

                                    </Collapse>

                                </Collapse>

                                <Divider/>
                            </>
                        )
                }

                <Divider/>
                <ListItem disablePadding>
                    <CustomListItem
                        button
                        component={Link}
                        to="/profile"
                        selected={location.pathname === "/profile"}
                    >
                        <ListItemIcon>
                            <AccountCircleIcon fontSize="large"/>
                        </ListItemIcon>
                        <ListItemText primary="Profile"/>
                    </CustomListItem>
                </ListItem>
                <Divider/>

                <ListItem disablePadding>
                    <ListItemButton onClick={handleLogout} style={{textDecoration: "none"}}>
                        <ListItemIcon>
                            <LogoutIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Logout"/>
                    </ListItemButton>
                </ListItem>
                <Divider/>

                <div className="lightDarkButton">
                    <ListItem disablePadding>
                        <ListItemButton className="listItemButton" onClick={() => dispatch({type: "DARK"})}>
                            <ListItemIcon>
                                <LightModeIcon
                                    className="lightModeIcon"
                                    sx={{
                                        color: orange[500],
                                        width: 40,
                                        height: 40,
                                    }}
                                />
                            </ListItemIcon>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton className="listItemButton" onClick={() => dispatch({type: "LIGHT"})}>
                            <ListItemIcon>
                                <DarkModeIcon
                                    className="darkModeIcon"
                                    sx={{
                                        color: grey[900],
                                        width: 40,
                                        height: 40,
                                    }}
                                />
                            </ListItemIcon>
                        </ListItemButton>
                    </ListItem>
                </div>
            </Drawer>
        </Box>
    );

}

export default Sidebar
