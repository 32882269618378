import './userProfile.scss'
import Sidebar from "../../components/sidebar/sidebar";
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";

const UserProfile = () => {

    const {currentUser} = useContext(AuthContext);
    const current_user_email = currentUser.email

    // This Table Is Also Used On The Home Dashboard
    return (
        <div className="userProfileContainer">
            <Sidebar/>
            <div className="userProfileContent">
                <h1 className="title">Profile Details</h1>
                <span className="email">Email: {current_user_email}</span>
                <p className="small">More will be added later</p>
            </div>
        </div>
    );
};

export default UserProfile;
