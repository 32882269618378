import './index.css'

import Home from "./pages/home/Home";
import Login from "./pages/auth/Login";
import Single from "./pages/single/Single";
import NewHitterGameStats from "./pages/new/Hitter/NewHitterGameStats";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import {
    addHitterGameStatsInput,
    addHitterInputs,
    addNewPitcherInputs,
    addPitcherGameStatsInput
} from "./formSource";
import './style/dark.scss'
import { useContext } from "react";
import { DarkModeContext } from "./context/darkModeContext";
import { AuthContext } from "./context/AuthContext";
import Welcome from "./pages/welcome/Welcome";
import Signup from "./pages/auth/Signup";
import NewHitter from "./pages/new/Hitter/NewHitter";
import NewPitcher from "./pages/new/Pitcher/NewPitcher";
import NewPitcherGameStats from "./pages/new/Pitcher/NewPitcherGameStats";
import AllPlayers from "./pages/AllPlayers/AllPlayers";
import TeamGameStats from "./pages/teamGameStats/TeamGameStats";
import NewTeamGameStats from "./pages/new/NewTeamGameStats/NewTeamGameStats";
import { addNewTeamGameStats } from "./formSource";
import PageNotFound from "./pages/404-PageNotFound/PageNotFound";
import UserProfile from "./pages/User-Profile/UserProfile";
import RankedStats from "./pages/GameModeStats/RankedStats/RankedStats";
import BRStats from "./pages/GameModeStats/BRStats/BRStats";
import CoOpStats from "./pages/GameModeStats/CoOpStats/coopStats";
import CustomLeagueStats from "./pages/GameModeStats/CustomLeageStats/CustomLeagueStats";
import PatchNotes from "./pages/Patch-Notes/PatchNotes";
import BulkAddStats from "./pages/new/BulkAddStats/BulkAddStats";

function App() {

    const {darkMode} = useContext(DarkModeContext);
    const {currentUser} = useContext(AuthContext);

    // console.log(currentUser)

    const RequireAuth = ({children}) => {

        return currentUser ? (children) : <Navigate to="/login"/>;

    }

    return (
        <div className={darkMode ? "app" : "app dark"}>
            <BrowserRouter>
                <Routes>

                    <Route path="/" index element={<Login/>}/>

                    <Route path="/">

                        <Route
                            exact
                            path="signup"
                            element={
                                <Signup/>
                            }
                        />

                        <Route
                            index
                            exact
                            path="login"
                            element={
                                <Login/>
                            }
                        />

                        <Route
                            path="welcome"
                            element={
                                <RequireAuth>
                                    <Welcome/>
                                </RequireAuth>
                            }
                        />

                        <Route
                            path="profile"
                            element={
                                <RequireAuth>
                                    <UserProfile/>
                                </RequireAuth>
                            }
                        />

                        <Route path=":year">
                            <Route
                                path="dashboard"
                                element={
                                    <RequireAuth>
                                        <Home/>
                                    </RequireAuth>
                                }
                            />

                            {/* Shows all hitters */}
                            <Route path="hitter">
                                <Route
                                    index
                                    element={
                                        <RequireAuth>
                                            <AllPlayers type={"Hitter"} title="All Hitters"/>
                                        </RequireAuth>
                                    }
                                />
                                <Route
                                    path="new"
                                    element={
                                        <RequireAuth>
                                            <NewHitter inputs={addHitterInputs} title="Add New Hitter"/>
                                        </RequireAuth>
                                    }
                                />
                            </Route>

                            {/* Shows all pitchers */}
                            <Route path="pitcher">
                                <Route
                                    index
                                    element={
                                        <RequireAuth>
                                            <AllPlayers type={"Pitcher"} title="All Pitchers"/>
                                        </RequireAuth>
                                    }
                                />
                                <Route
                                    path="new"
                                    element={
                                        <RequireAuth>
                                            <NewPitcher inputs={addNewPitcherInputs} title="Add New Pitcher"/>
                                        </RequireAuth>
                                    }
                                />
                            </Route>

                            <Route path="new">
                                <Route
                                    path="playerStats/:type"
                                    element={
                                        <RequireAuth>
                                            <BulkAddStats/>
                                        </RequireAuth>
                                    }
                                />
                            </Route>

                            <Route path="player">
                                <Route
                                    path=":playerName/:playerCardType/:type"
                                    element={
                                        <RequireAuth>
                                            <Single/>
                                        </RequireAuth>
                                    }
                                />
                            </Route>

                            <Route path="games">
                                {/* Hitters */}
                                <Route
                                    path="hitters/player-stats"
                                    element={
                                        <RequireAuth>
                                            {/*<PlayerGameStats type={"Hitter"} title={"Add Hitter Game GameModeStats"} />*/}
                                        </RequireAuth>
                                    }
                                />
                                <Route
                                    path="hitters-new/:playerName/:playerCardType"
                                    element={
                                        <RequireAuth>
                                            <NewHitterGameStats inputs={addHitterGameStatsInput}
                                                                title="Add New Hitter Game Stats"/>
                                        </RequireAuth>
                                    }
                                />
                                <Route
                                    path="pitchers-new/:playerName/:playerCardType"
                                    element={
                                        <RequireAuth>
                                            <NewPitcherGameStats inputs={addPitcherGameStatsInput}
                                                                 title="Add New Pitcher Game Stats"/>
                                        </RequireAuth>
                                    }
                                />

                            </Route>

                            {/* Team GameModeStats */}
                            <Route path="teamGameStats">
                                <Route
                                    index
                                    element={
                                        <RequireAuth>
                                            <TeamGameStats/>
                                        </RequireAuth>
                                    }
                                />
                                <Route
                                    path="new"
                                    element={
                                        <RequireAuth>
                                            <NewTeamGameStats inputs={addNewTeamGameStats}
                                                              title="Add New Team Game Stats"/>
                                        </RequireAuth>
                                    }
                                />
                            </Route>


                            {/* Stat Mode Pages */}
                            <Route path="stats">

                                <Route
                                    path="rankedStats"
                                    element={
                                        <RequireAuth>
                                            <RankedStats
                                                title="Solo Ranked Stats"
                                            />
                                        </RequireAuth>
                                    }
                                />

                                <Route
                                    path="brStats"
                                    element={
                                        <RequireAuth>
                                            <BRStats
                                                title="Battle Royale Stats"
                                            />
                                        </RequireAuth>
                                    }
                                />

                                <Route
                                    path="coopStats"
                                    element={
                                        <RequireAuth>
                                            <CoOpStats
                                                title="Co-Op Ranked Stats"
                                            />
                                        </RequireAuth>
                                    }
                                />

                                <Route
                                    path="customLeagueStats"
                                    element={
                                        <RequireAuth>
                                            <CustomLeagueStats
                                                title="Custom League Stats"
                                            />
                                        </RequireAuth>
                                    }
                                />

                            </Route>

                            <Route
                                path="patch-notes"
                                element={
                                    <RequireAuth>
                                        <PatchNotes/>
                                    </RequireAuth>
                                }
                            />

                        </Route>
                        {/* End of :year routes */}

                    </Route> {/*  end of / routes  */}

                    <Route path="*" element={<PageNotFound/>}/>

                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
