// For Showing Stats
export const singleHitterTableColumns = [
    {
        field: "hitterName",
        headerName: "Player Name",
        width: 150,
        headerAlign: 'center',
        align: 'left',
    },
    {
        field: "hitterAppearances",
        headerName: "PA",
        width: 80,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "hitterAtBats",
        headerName: "AB",
        width: 80,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "hitterHits",
        headerName: "Hits",
        width: 80,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "hitterSingles",
        headerName: "1B",
        width: 80,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "hitterDoubles",
        headerName: "2B",
        width: 80,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "hitterTriples",
        headerName: "3B",
        width: 80,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "hitterHomeRuns",
        headerName: "HR",
        width: 80,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "hitterRunsBattedIn",
        headerName: "RBI",
        width: 80,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "hitterWalks",
        headerName: "BB",
        width: 80,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "hitterStrikeouts",
        headerName: "SO",
        width: 80,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
];

export const singlePitcherTableColumns = [
    {
        field: "pitcherName",
        headerName: "Player Name",
        width: 150,
        headerAlign: 'center',
        align: 'left',
    },
    {
        field: "pitcherInningsPitched",
        headerName: "IP",
        width: 80,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "pitcherWins",
        headerName: "W",
        width: 80,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "pitcherLosses",
        headerName: "L",
        width: 80,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "pitcherSaves",
        headerName: "S",
        width: 80,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "pitcherStrikeouts",
        headerName: "SO",
        width: 80,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "pitcherWalks",
        headerName: "BB",
        width: 80,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "pitcherHits",
        headerName: "H",
        width: 80,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "pitcherEarnedRuns",
        headerName: "ER",
        width: 80,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "pitcherHomeruns",
        headerName: "HR",
        width: 80,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "pitcherGamesStarted",
        headerName: "GS",
        width: 80,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "pitcherCompleteGames",
        headerName: "CG",
        width: 80,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "pitcherShutouts",
        headerName: "SHO",
        width: 80,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "pitcherQualityStarts",
        headerName: "QS",
        width: 80,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
];


export const allHitterColumns = [
    {
        field: "hitterName",
        headerName: "Player Name",
        width: 150,
        headerAlign: 'center',
        align: 'left',
        type: "string",
    },
    {
        field: "cardType",
        headerName: "Card Type",
        width: 150,
        headerAlign: 'center',
        align: 'center',
        type: "string",
    },
    {
        field: "hitterGamesPlayed",
        headerName: "Games Played",
        width: 120,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "hitterAppearances",
        headerName: "PA",
        width: 80,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "hitterAtBats",
        headerName: "AB",
        width: 80,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "hitterHits",
        headerName: "Hits",
        width: 80,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "hitterSingles",
        headerName: "1B",
        width: 80,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "hitterDoubles",
        headerName: "2B",
        width: 80,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "hitterTriples",
        headerName: "3B",
        width: 80,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "hitterHomeRuns",
        headerName: "HR",
        width: 80,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "hitterRunsBattedIn",
        headerName: "RBI",
        width: 80,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "hitterWalks",
        headerName: "BB",
        width: 80,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "hitterStrikeouts",
        headerName: "SO",
        width: 80,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "hitterAverage",
        headerName: "AVG",
        width: 80,
        headerAlign: 'center',
        align: 'center',
        type: "number",
        valueGetter: (params) => {
            const atBats = parseFloat(params.row.hitterAtBats);
            const hits = parseFloat(params.row.hitterHits);
            const average = atBats > 0 ? hits / atBats : 0;
            return average;
        },
    },
    {
        field: "onBasePercentage",
        headerName: "OBP",
        width: 80,
        headerAlign: 'center',
        align: 'center',
        type: "number",
        valueGetter: (params) => {
            const atBats = parseInt(params.row.hitterAtBats)
            const hits = parseInt(params.row.hitterHits)
            const walks = parseInt(params.row.hitterWalks)

            return ((hits + walks) / (atBats + walks)).toFixed(3);
        },
    },
    {
        field: "slugging",
        headerName: "SLG",
        width: 80,
        headerAlign: 'center',
        align: 'center',
        type: "number",
        valueGetter: (params) => {
            const atBats = parseInt(params.row.hitterAtBats)
            const singles = parseInt(params.row.hitterSingles)
            const doubles = parseInt(params.row.hitterDoubles)
            const triples = parseInt(params.row.hitterTriples)
            const homeruns = parseInt(params.row.hitterHomeRuns)

            return ((singles + (doubles * 2) + (triples * 3) + (homeruns * 4)) / atBats).toFixed(3)

        },
    },
    {
        field: "onBasePlusSlugging",
        headerName: "OPS",
        width: 80,
        headerAlign: 'center',
        align: 'center',
        type: "number",
        valueGetter: (params) => {

            const atBats = parseInt(params.row.hitterAtBats)
            const hits = parseInt(params.row.hitterHits)
            const walks = parseInt(params.row.hitterWalks)
            const singles = parseInt(params.row.hitterSingles)
            const doubles = parseInt(params.row.hitterDoubles)
            const triples = parseInt(params.row.hitterTriples)
            const homeruns = parseInt(params.row.hitterHomeRuns)


            const obp = parseFloat(((hits + walks) / (atBats + walks)).toFixed(3));
            const slg = parseFloat(((singles + (doubles * 2) + (triples * 3) + (homeruns * 4)) / atBats).toFixed(3));

            const results = (obp + slg).toFixed(3);
            return results;
        },
    },
];

export const allPitcherColumns = [
    {
        field: "pitcherName",
        headerName: "Player Name",
        width: 150,
        headerAlign: 'center',
        align: 'left',
    },
    {
        field: "cardType",
        headerName: "Card Type",
        width: 150,
        headerAlign: 'center',
        align: 'center',
        type: "string",
    },
    {
        field: "pitcherGamesPlayed",
        headerName: "Games Played",
        width: 120,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "pitcherInningsPitched",
        headerName: "IP",
        width: 80,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "pitcherWins",
        headerName: "W",
        width: 80,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "pitcherLosses",
        headerName: "L",
        width: 80,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "pitcherSaves",
        headerName: "S",
        width: 80,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "pitcherStrikeouts",
        headerName: "SO",
        width: 80,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "pitcherWalks",
        headerName: "BB",
        width: 80,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "pitcherHits",
        headerName: "H",
        width: 80,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "pitcherEarnedRuns",
        headerName: "ER",
        width: 80,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "pitcherHomeruns",
        headerName: "HR",
        width: 80,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "pitcherGamesStarted",
        headerName: "GS",
        width: 80,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "pitcherCompleteGames",
        headerName: "CG",
        width: 80,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "pitcherShutouts",
        headerName: "SHO",
        width: 80,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "pitcherQualityStarts",
        headerName: "QS",
        width: 80,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
];

export const teamGameStats = [
    {
        field: "timestamp",
        headerName: "Game Date",
        width: 100,
        headerAlign: 'center',
        align: 'center',
        type: "date",
    },
    {
        field: "gameLocation",
        headerName: "Home or Away",
        width: 130,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "gameTime",
        headerName: "Time of Day",
        width: 100,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "gameWinCounter",
        headerName: "Win?",
        width: 80,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "gameLossCounter",
        headerName: "Loss?",
        width: 80,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "gameRunsFor",
        headerName: "Runs For",
        width: 100,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "gameRunsAgainst",
        headerName: "Runs Against",
        width: 100,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "hitsFor",
        headerName: "Hits For",
        width: 80,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "hitsAgainst",
        headerName: "Hits Against",
        width: 100,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "ratingBefore",
        headerName: "Rating Before",
        width: 100,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "ratingAfter",
        headerName: "Rating After",
        width: 100,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "rageQuit",
        headerName: "Rage Quit",
        width: 100,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "seasonNum",
        headerName: "Season #",
        width: 100,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "year",
        headerName: "Game Year",
        width: 100,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
];


export const gameModeGameLog = [
    {
        field: "gameNumber",
        headerName: "Game #",
        width: 100,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "timestamp",
        headerName: "Game Date",
        width: 100,
        headerAlign: 'center',
        align: 'center',
        type: "date",
    },
    {
        field: "gameLocation",
        headerName: "Home or Away",
        width: 130,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "gameTime",
        headerName: "Time of Day",
        width: 100,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "gameWinCounter",
        headerName: "Win?",
        width: 80,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "gameLossCounter",
        headerName: "Loss?",
        width: 80,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "gameRunsFor",
        headerName: "Runs For",
        width: 100,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "gameRunsAgainst",
        headerName: "Runs Against",
        width: 100,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "hitsFor",
        headerName: "Hits For",
        width: 80,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "hitsAgainst",
        headerName: "Hits Against",
        width: 100,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "ratingBefore",
        headerName: "Rating Before",
        width: 100,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "ratingAfter",
        headerName: "Rating After",
        width: 100,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "rageQuit",
        headerName: "Rage Quit",
        width: 100,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    },
    {
        field: "seasonNum",
        headerName: "Season #",
        width: 100,
        headerAlign: 'center',
        align: 'center',
        type: "number",
    }
];