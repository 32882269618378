import './home.scss';

import Sidebar from "../../components/sidebar/sidebar";
import Widget from "../../components/widgets/Widgets";
import Charts from "../../components/chart/charts";
import Footer from "../../components/footer/footer";

const Home = () => {
    return (
        <div className="home">
            <Sidebar/>
            <div className="homeContainer">
                <h1>Overall Stats</h1>
                <h2>Individual Records</h2>
                <div className="widgets">
                    <Widget type="record"/>
                    <Widget type="dayRecord"/>
                    <Widget type="nightRecord"/>
                    <Widget type="homeRecord"/>
                    <Widget type="awayRecord"/>
                </div>
                <h2>Game Stats</h2>
                <div className="widgets">
                    <Widget type="runsForAgainst"/>
                    <Widget type="hitsForAgainst"/>
                    <Widget type="homeruns"/>
                    <Widget type="strikeouts"/>
                    <Widget type="hitsForAgainstPerGame"/>
                    <Widget type="runsForAgainstPerGame"/>
                </div>
                <h2>Team Stats</h2>
                <div className="widgets">
                    <Widget type="avg"/>
                    <Widget type="era"/>
                </div>
                <div className="charts">
                    <Charts
                        titleLeft="Hitters Quick Stats"
                        titleRight="Pitchers Quick Stats"
                    />
                </div>
                <Footer/>
            </div>
        </div>
    );
}

export default Home
