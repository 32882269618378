import '../new.scss';

import { useContext, useState } from "react";

import { useParams } from "react-router-dom";
import Sidebar from "../../../components/sidebar/sidebar";
import { AuthContext } from "../../../context/AuthContext";
import supabase from "../../../config/supabaseClient";
import Footer from "../../../components/footer/footer";
import Swal from "sweetalert2";

const NewHitter = ({inputs, title}) => {

    const initializeData = () => {
        const initialData = {};
        inputs.forEach(input => {
            if (input.type === "select") {
                initialData[input.id] = input.defaultValue;
            } else {
                initialData[input.id] = '';
            }
        });
        return initialData;
    };

    const [data, setData] = useState(initializeData());
    const [player, setPlayer] = useState({})
    const [fetchError, setFetchError] = useState("")
    const {year} = useParams();
    const {currentUser} = useContext(AuthContext);
    const current_user_email = currentUser.email
    // console.log(currentUser)

    // console.log(currentUser.id)

    const handleInput = (e) => {

        const id = e.target.id;
        const value = e.target.value

        setData({...data, [id]: value});

        const playerFullName = (data.hitterName + data.cardType + year).replace(/\s/g, '')
        setPlayer(playerFullName)

        // console.log(playerFullName)

        //console.log(data)

    }

    const handleAdd = async (e) => {

        e.preventDefault();

        try {

            const { error } = await supabase
                .from('hitters')
                .insert({
                    user_id: currentUser.id,
                    ...data,
                    hitterGamesPlayed: 0,
                    hitterAppearances: 0,
                    hitterAtBats: 0,
                    hitterHits: 0,
                    hitterSingles: 0,
                    hitterDoubles: 0,
                    hitterTriples: 0,
                    hitterHomeRuns: 0,
                    hitterRunsBattedIn: 0,
                    hitterWalks: 0,
                    hitterStrikeouts: 0,
                    playerNameCardType: player,
                    user_email: current_user_email,
                    year: year
                })

            if (error) {
                setFetchError(error);
            }

            await Swal.fire({
                title: "Hitter added successfully",
                icon: "success",
                showCancelButton: true,
                confirmButtonText: "OK",
                cancelButtonText: "Back to previous page",
            }).then((result) => {
                if (result.isConfirmed) {
                    // handle OK button click
                    setData({});
                    setPlayer({});
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    // handle "Back to previous page" button click
                    window.history.back();
                }
            });

        } catch (error) {
            console.log(error)
        }

    }

    // console.log(JSON.parse(localStorage.getItem('user')).email)

    return (
        <div className="new">
            <Sidebar/>
            <div className="newContainer">
                <div className="top">
                    <h1>{title}</h1>
                </div>
                <div className="bottom">
                    <div className="content">
                        {fetchError && (<p>{fetchError}</p>)}
                        <form onSubmit={handleAdd}>
                            {inputs.map((input) => (
                                <div className="formInput" key={input.id}>
                                    <label htmlFor={input.id}>{input.label}</label>
                                    {input.type === "select" ? (
                                        <select
                                            id={input.id}
                                            onChange={handleInput}
                                            value={data[input.id]}  // Bind the value to state
                                            required
                                        >
                                            {input.options.map((option) => (
                                                <option
                                                    key={option.value}
                                                    value={option.value}
                                                >
                                                    {option.label}
                                                </option>
                                            ))}
                                        </select>
                                    ) : (
                                        <input
                                            id={input.id}
                                            type={input.type}
                                            placeholder={input.placeholder}
                                            onChange={handleInput}
                                            value={data[input.id] || ''}  // Bind the value to state
                                            required
                                        />
                                    )}
                                </div>
                            ))}
                            {
                                current_user_email === 'baseballstatstrackertestuser@gmail.com'
                                ? (
                                    <h3>You cannot submit that content</h3>
                                ) : (
                                        <button type="submit">Send</button>
                                )
                            }
                        </form>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default NewHitter