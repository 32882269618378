import './profile.scss'
import supabase from "../../config/supabaseClient";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { Grid } from "@mui/material";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";

const Profile = (props) => {

    const [fetchError, setFetchError] = useState("")
    const [playerPageData, setPlayerPageData] = useState("")

    const {year} = useParams();

    const {currentUser} = useContext(AuthContext);
    const current_user_email = currentUser.email

    // console.log(props.player)
    // console.log(props.type)

    const player = props.player
    const type = props.type

    const fetchPlayerProfileInfo = async () => {

        if (type === "Hitter") {

            const {data, error} = await supabase
                .from('hitters')
                .select()
                .like('playerNameCardType', player)
                .eq("user_email", current_user_email)
                .eq("year", year)

            // console.log(data)

            if (error) {

                setFetchError('Some error occurred')
                setPlayerPageData(null)
                console.log(error)

            }
            if (data) {
                setPlayerPageData(data)
                setFetchError(null)
            }

        } else if (type === "Pitcher") {

            const {data, error} = await supabase
                .from('pitchers')
                .select()
                .like('playerNameCardType', player)
                .eq("user_email", current_user_email)
                .eq("year", year)


            // console.log(data)

            if (error) {

                setFetchError('Some error occurred')
                setPlayerPageData(null)
                console.log(error)

            }
            if (data) {
                setPlayerPageData(data)
                setFetchError(null)
            }

        }

    }

    useEffect(() => {
        fetchPlayerProfileInfo()
    }, [type])


    if (type === "Hitter") {

        for (let i = 0; i < playerPageData.length; i++) {

            // console.log(playerPageData[i])

            const playerName = playerPageData[i].hitterName
            const playerCardType = playerPageData[i].cardType
            const hitterOverall = playerPageData[i].hitterOverall
            const hitterPosition = playerPageData[i].hitterPosition

            return (
                <div className="profile-details">
                    <h1 className="itemTitle">{playerName}</h1>
                    <div className="detailItem">
                        <span className="itemKey">Card Type:</span>
                        <span className="itemValue">{playerCardType}</span>
                    </div>
                    <div className="detailItem">
                        <span className="itemKey">Overall:</span>
                        <span className="itemValue">{hitterOverall}</span>
                    </div>
                    <div className="detailItem">
                        <span className="itemKey">Position:</span>
                        <span className="itemValue">{hitterPosition}</span>
                    </div>
                </div>

            );

        }

    } else if (type === "Pitcher") {

        for (let i = 0; i < playerPageData.length; i++) {

            const playerName = playerPageData[i].pitcherName
            const playerCardType = playerPageData[i].cardType
            const pitcherOverall = playerPageData[i].pitcherOverall
            const pitcherPosition = playerPageData[i].pitcherPosition

            return (
                <div className="profile-details">
                    {fetchError &&
                        <Grid
                            container
                            className="errorMessage"
                        >
                            <Grid item>
                                Something went wrong.
                            </Grid>
                            <Grid item>
                                <SentimentVeryDissatisfiedIcon fontSize="large" className="icon"/>
                            </Grid>
                        </Grid>
                    }
                    <h1 className="itemTitle">{playerName}</h1>
                    <div className="detailItem">
                        <span className="itemKey">Card Type:</span>
                        <span className="itemValue">{playerCardType}</span>
                    </div>
                    <div className="detailItem">
                        <span className="itemKey">Overall:</span>
                        <span className="itemValue">{pitcherOverall}</span>
                    </div>
                    <div className="detailItem">
                        <span className="itemKey">Position:</span>
                        <span className="itemValue">{pitcherPosition}</span>
                    </div>
                </div>

            );

        }

    }

}

export default Profile
