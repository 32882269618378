import './pageNotFound.scss'
import { Grid } from "@mui/material";
import ErrorIcon from '@mui/icons-material/Error';
import Sidebar from "../../components/sidebar/sidebar";
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";


const PageNotFound = () => {

    const {currentUser} = useContext(AuthContext);
    const current_user_email = currentUser.email

    return (
        <div className="pageNotFound">

            {
                typeof current_user_email === 'undefined' || current_user_email === null

                ?

                    <>
                        <Sidebar/>
                        <div className="content">

                            <h1>Error 404</h1>

                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Grid item>
                                    Page Not Found, Please Go Back
                                </Grid>
                                <Grid item>
                                    <ErrorIcon fontSize="large"/>
                                </Grid>
                            </Grid>

                        </div>
                    </>

                :
                <h1>Page Not Found, Please Go Back</h1>

            }

        </div>

    );

}

export default PageNotFound;
