import "./singlePlayerTable.scss";

import {
    singleHitterTableColumns,
    singlePitcherTableColumns
} from "../../datatablesource";
import { DataGrid } from "@mui/x-data-grid";
import { useParams } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import supabase from "../../config/supabaseClient";
import { AuthContext } from "../../context/AuthContext";
import { Button, Grid } from "@mui/material";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import Swal from "sweetalert2";

const SinglePlayerTable = () => {

    const [fetchError, setFetchError] = useState("")
    const [playerPageData, setPlayerPageData] = useState("")

    const {year} = useParams();
    const {currentUser} = useContext(AuthContext);
    const current_user_email = currentUser.email
    const {playerName} = useParams();
    const {playerCardType} = useParams();
    const {type} = useParams()

    // console.log(playerName)
    // console.log(playerCardType)

    const player = (playerName + playerCardType + year).replace(/\s/g, '')
    // console.log(player)

    const fetchRecentPlayerData = async () => {

        if (type === "Hitter") {

            const {data, error} = await supabase
                .from('hittersGameStats')
                .select()
                .like('playerNameCardType', player)
                .eq("user_email", current_user_email)
                .eq("year", year)

            // console.log(data)

            if (error) {

                setFetchError('Some error occurred')
                setPlayerPageData(null)
                console.log(error)

            }
            if (data) {
                setPlayerPageData(data)
                setFetchError(null)
            }

        } else if (type === "Pitcher") {

            const {data, error} = await supabase
                .from('pitchersGameStats')
                .select()
                .like('playerNameCardType', player)
                .eq("user_email", current_user_email)
                .eq("year", year)


            // console.log(data)

            if (error) {

                setFetchError(error)
                setPlayerPageData(null)
                console.log(error)

            }
            if (data) {
                setPlayerPageData(data)
                setFetchError(null)
            }

        }

    }

    // console.log(playerPageData)

    useEffect(() => {
        fetchRecentPlayerData()
    }, [type, current_user_email])

    const handleButtonClick = async (id) => {

        const {data: row, error} = await supabase
            .from('hittersGameStats')
            .select("*")
            .eq("id", id)
            .eq("year", year)
            .eq("user_email", current_user_email)
            .single();

        if (error) {
            console.error(error);
            return;
        }

        console.log(row);

        Swal.fire({
            title: `Edit ${row.hitterName}'s Stats`,
            html: `
                  <form class="modal-form">
                    <div class="form-group">
                        <label for="hitterAppearances">PA</label>
                        <input type="text" style="text-align: center" class="form-control" id="hitterAppearances" value="${row.hitterAppearances}" required>
                    </div>
                    <div class="form-group">
                        <label for="hitterAtBats">AB</label>
                        <input type="text" style="text-align: center" class="form-control" id="hitterAtBats" value="${row.hitterAtBats}" required>
                    </div>
                    <div class="form-group">
                        <label for="hitterHits">Hits</label>
                        <input type="text" style="text-align: center" class="form-control" id="hitterHits" value="${row.hitterHits}" required>
                    </div>
                    <div class="form-group">
                        <label for="hitterSingles">1B</label>
                        <input type="text" style="text-align: center" class="form-control" id="hitterSingles" value="${row.hitterSingles}" required>
                    </div>
                    <div class="form-group">
                        <label for="hitterDoubles">2B</label>
                        <input type="text" style="text-align: center" class="form-control" id="hitterDoubles" value="${row.hitterDoubles}" required>
                    </div>
                    <div class="form-group">
                        <label for="hitterTriples">3B</label>
                        <input type="text" style="text-align: center" class="form-control" id="hitterTriples" value="${row.hitterTriples}" required>
                    </div>
                    <div class="form-group">
                        <label for="hitterHomeRuns">HR</label>
                        <input type="text" style="text-align: center" class="form-control" id="hitterHomeRuns" value="${row.hitterHomeRuns}" required>
                    </div>
                    <div class="form-group">
                        <label for="hitterRunsBattedIn">RBI</label>
                        <input type="text" style="text-align: center" class="form-control" id="hitterRunsBattedIn" value="${row.hitterRunsBattedIn}" required>
                    </div>
                    <div class="form-group">
                        <label for="hitterWalks">BB</label>
                        <input type="text" style="text-align: center" class="form-control" id="hitterWalks" value="${row.hitterWalks}" required>
                    </div>
                    <div class="form-group">
                        <label for="hitterStrikeouts">SO</label>
                        <input type="text" style="text-align: center" class="form-control" id="hitterStrikeouts" value="${row.hitterStrikeouts}" required>
                    </div>
    
                  </form>
                `,
            showCancelButton: true,
            confirmButtonText: 'Save Changes',
            focusConfirm: false,
            preConfirm: () => {
                const hitterAppearances = Swal.getPopup().querySelector('#hitterAppearances').value;
                const hitterAtBats = Swal.getPopup().querySelector('#hitterAtBats').value;
                const hitterHits = Swal.getPopup().querySelector('#hitterHits').value;
                const hitterSingles = Swal.getPopup().querySelector('#hitterSingles').value;
                const hitterDoubles = Swal.getPopup().querySelector('#hitterDoubles').value;
                const hitterTriples = Swal.getPopup().querySelector('#hitterTriples').value;
                const hitterHomeRuns = Swal.getPopup().querySelector('#hitterHomeRuns').value;
                const hitterRunsBattedIn = Swal.getPopup().querySelector('#hitterRunsBattedIn').value;
                const hitterWalks = Swal.getPopup().querySelector('#hitterWalks').value;
                const hitterStrikeouts = Swal.getPopup().querySelector('#hitterStrikeouts').value;

                if (
                    !hitterAppearances ||
                    !hitterAtBats ||
                    !hitterHits ||
                    !hitterSingles ||
                    !hitterDoubles ||
                    !hitterTriples ||
                    !hitterHomeRuns ||
                    !hitterRunsBattedIn ||
                    !hitterWalks ||
                    !hitterStrikeouts
                ) {
                    Swal.showValidationMessage('All fields are required');
                }

                const hitterResults = {
                    hitterAppearances: hitterAppearances,
                    hitterAtBats: hitterAtBats,
                    hitterHits: hitterHits,
                    hitterSingles: hitterSingles,
                    hitterDoubles: hitterDoubles,
                    hitterTriples: hitterTriples,
                    hitterHomeRuns: hitterHomeRuns,
                    hitterRunsBattedIn: hitterRunsBattedIn,
                    hitterWalks: hitterWalks,
                    hitterStrikeouts: hitterStrikeouts
                }

                // console.log(hitterResults)

                return hitterResults
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                // console.log(result.value);

                const {data, error} = await supabase
                    .from('hittersGameStats')
                    .update(result.value)
                    .eq('id', id)
                    .eq("year", year)
                    .eq("user_email", current_user_email);

                if (error) {
                    console.error(error);
                    await Swal.fire({
                        title: 'Error',
                        text: 'An error occurred while saving changes.',
                        icon: 'error'
                    });
                } else {
                    await Swal.fire({
                        title: 'Changes Saved!',
                        icon: 'success'
                    });
                }

            }
        });

    };

    const editStatsButton = [
        {
            field: "pitcherAction",
            headerName: "Edit Player Stats",
            width: 200,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {

                const row_id = params.row.id

                return (
                    <>
                        {
                            current_user_email === 'baseballstatstrackertestuser@gmail.com'
                                ? (
                                    <></>
                                ) : (
                                    <>
                                        <div>
                                            <Button onClick={() => handleButtonClick(params.row.id)}>Edit</Button>
                                        </div>
                                    </>
                                )
                        }
                    </>
                );
            },
        },
    ]


    // This Table Is Also Used On The Home Dashboard
    return (
        <div className="singlePlayerTable" style={{height: 550, width: '100%', textAlign: 'center', marginTop: 20}}>
            {fetchError &&
                <Grid
                    container
                    className="errorMessage"
                >
                    <Grid item>
                        Something went wrong.
                    </Grid>
                    <Grid item>
                        <SentimentVeryDissatisfiedIcon fontSize="large" className="icon"/>
                    </Grid>
                </Grid>
            }
            {type === "Hitter"

                ? <DataGrid
                    className="datagrid"
                    rows={playerPageData}
                    columns={singleHitterTableColumns.concat(editStatsButton)}
                    pageSize={15}
                    rowsPerPageOptions={[15]}
                    checkboxSelection
                />

                : <DataGrid
                    className="datagrid"
                    rows={playerPageData}
                    columns={singlePitcherTableColumns.concat(editStatsButton)}
                    pageSize={15}
                    rowsPerPageOptions={[15]}
                    checkboxSelection
                />

            }
        </div>
    );
};

export default SinglePlayerTable;
